import {type PropsWithChildren} from 'react';

import {Grid} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

const StyledOptixTableGridRoot = styled(Grid.Root)(() => ({
  flex: 1,
  flexGrow: 1,
}));

export function OptixTableGridRoot({children}: PropsWithChildren) {
  return <StyledOptixTableGridRoot>{children}</StyledOptixTableGridRoot>;
}
