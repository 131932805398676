import {type PaletteOptions} from '@mui/material/styles';

export const ThemeDarkPalette: PaletteOptions = {
  background: {
    default: '#222',
    paper: '#323232',
  },
  text: {
    primary: '#E7E7E7',
    secondary: '#A0A0A0',
    disabled: '#717171',
  },
};
