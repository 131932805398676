import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import {css, styled} from '@mui/material/styles';

// import {ReactComponent as CheckEmpty} from '../../assets/images/svgs/checkbox_base_empty.svg';
// import {ReactComponent as CheckIndeterminate} from '../../assets/images/svgs/checkbox_base_indeterminate.svg';
// import {ReactComponent as CheckTicked} from '../../assets/images/svgs/checkbox_base_ticked.svg';

const sizeMap = {
  small: css`
    width: 16px;
    height: 16px;
  `,
  medium: css`
    width: 20px;
    height: 20px;
  `,
  large: css`
    width: 24px;
    height: 24px;
  `,
};

// fill: ${({theme}) => theme.palette.surface.warmGrey100};
// box-shadow: 0 0 0 4px
//   ${({theme}) => theme?.palette?.surface?.warmGrey100 ?? ''};
export const StyledOptixCheckbox = styled(Checkbox)`
  background: transparent;
  padding: 0;
  &:hover {
    background: transparent;
    svg {
    }
  }
  &:focus {
    svg {
      border-radius: 4px;
    }
  }
  svg {
    ${({size}) => sizeMap[size ?? 'small']}
  }
`;

// export const StyledCheckTicked = styled(CheckTicked)`
//   ${({theme}) => css`
//     fill: transparent;
//     stroke: ${theme.palette.brand.optixOrange};
//   `}
// `;
//
// export const StyledCheckEmpty = styled(CheckEmpty)`
//   ${({theme}) => css`
//     fill: transparent;
//     stroke: ${theme.palette.textColors.textHigh};
//   `}
// `;
//
// export const StyledCheckIndeterminate = styled(CheckIndeterminate)`
//   ${({theme}) => css`
//     fill: transparent;
//     stroke: ${theme.palette.brand.optixOrange};
//   `}
// `;

export const StyledCheckboxHelper = styled(FormHelperText)`
  color: ${({theme}) => theme.palette.text.secondary};
  margin: 4px 14px 0;
`;
