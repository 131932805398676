const empowerMe = {
  get() {
    return '/empowerMe';
  },
  get events() {
    return `${this.get()}/events`;
  },
  get drivers() {
    return `${this.get()}/drivers`;
  },
  get vehicles() {
    return `${this.get()}/vehicles`;
  },
  get training() {
    return `${this.get()}/training`;
  },
  get behaviours() {
    return `${this.get()}/behaviours`;
  },
  get uploadUser() {
    return `${this.get()}/uploadUser`;
  },
  get users() {
    return `${this.get()}/users`;
  },
  get reports() {
    return `${this.get()}/reports`;
  },
  get organisations() {
    return `${this.get()}/organisations`;
  },
  get organisationGroups() {
    return `${this.get()}/organisationGroups`;
  },
} as const;

const recruitMe = {
  get() {
    return '/recruitMe';
  },
  get users() {
    return `${this.get()}/users`;
  },
  get reports() {
    return `${this.get()}/reports`;
  },
  get assessmentChat() {
    return `${this.get()}/assessmentChat`;
  },
  get feedbackChat() {
    return `${this.get()}/feedbackChat`;
  },
  get candidates() {
    return `${this.get()}/candidates`;
  },
} as const;

const trainMe = {
  get() {
    return '/trainMe';
  },
  get candidates() {
    return `${this.get()}/candidates`;
  },
  get scheduler() {
    return `${this.get()}/scheduler`;
  },
  get uploadUser() {
    return `${this.get()}/uploadUser`;
  },
} as const;

export const routes = {
  get notFound() {
    return '*';
  },
  get landing() {
    return '/';
  },
  get authRedirect() {
    return '/auth/redirect';
  },
  get login() {
    return '/login';
  },
  get users() {
    return '/users';
  },
  get reports() {
    return '/reports';
  },
  get profile() {
    return '/profile';
  },
  empowerMe,
  recruitMe,
  trainMe,
} as const;
