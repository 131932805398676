import {HomeCard} from './HomeCard';
import TrainMeSmall from '../../../assets/images/small/train-me-small.png';
import TrainMe from '../../../assets/images/train-me.png';
import {TrainMePrimaryColor} from '../../../theme/ThemeTrainMePalette';
import {routes} from '../../common/constants/routes';

export default function HomeTrainMeCard() {
  return (
    <HomeCard
      cover={TrainMe}
      smallCoverUrl={TrainMeSmall}
      title="Train Me"
      background={TrainMePrimaryColor.main}
      path={routes.trainMe.scheduler}
    />
  );
}
