import {type PaletteOptions} from '@mui/material/styles';
import {create} from 'zustand';

interface ThemeStoreState {
  palette: PaletteOptions | null;
}

interface ThemeStoreActions {
  reset: () => void;
}

const themeStoreInitialState: ThemeStoreState = {
  palette: null,
};

export type ThemeStore = ThemeStoreState & ThemeStoreActions;

export const useThemeStore = create<ThemeStore>((setState) => ({
  ...themeStoreInitialState,
  reset: () => {
    setState(themeStoreInitialState);
  },
}));
