import {useCallback, useState} from 'react';
import {type FieldPath, type FieldValues} from 'react-hook-form';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import {type OptixTextFieldProps} from '../../../textField';
import {type ControllerRenderProps} from '../../formTypes';

export type OptixTextFieldHookRenderProps = Omit<
  OptixTextFieldProps,
  'name' | 'defaultValue' | 'value' | 'onChange' | 'onBlur'
> & {
  isPassword?: boolean;
};

export type OptixTextFieldHookRenderComponentProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerRenderProps<TFieldValues, TName> & OptixTextFieldHookRenderProps;

export function OptixTextFieldHookRender<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field,
  fieldState,
  formState,
  isPassword = false,
  ...textFieldProps
}: OptixTextFieldHookRenderComponentProps<TFieldValues, TName>) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const errorMessage = fieldState.error?.message;

  const handleToggleIsPasswordVisible = useCallback(() => {
    setIsPasswordVisible((prevState) => !prevState);
  }, []);

  /** We place the {...textFieldProps} after the "local" props so that they can be overwritten */
  return (
    <TextField
      type={isPassword && !isPasswordVisible ? 'password' : 'text'}
      helperText={errorMessage}
      size="medium"
      error={!(errorMessage == null)}
      end={
        isPassword ? (
          <IconButton size="small" onClick={handleToggleIsPasswordVisible}>
            {isPasswordVisible ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        ) : undefined
      }
      {...textFieldProps}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      inputRef={field.ref}
      value={field.value}
      /**
       * This will make the textField fullWidth true by default when you don't
       * pass a fullWidth.
       * Pass `fullWidth={false}`, if you don't want it to have fullWidth.
       */
      fullWidth={
        textFieldProps.fullWidth === undefined || textFieldProps.fullWidth
      }
    />
  );
}
