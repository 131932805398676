import {
  type ToastOptions,
  type TypeOptions,
  toast as reactToast,
} from 'react-toastify';

export const toast = (
  message: string,
  type?: TypeOptions | undefined,
  options?: ToastOptions | undefined,
) => {
  /** Adding message as the default toastId makes sure that duplicate toasts won't be displayed */
  reactToast(message, {type, toastId: message, ...options});
};
