import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import ArrowForward from '@mui/icons-material/ArrowForward';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {styled, type SxProps, type Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface EmpowerMeCardSummaryProps {
  title: string;
  value: string | number | undefined | null;
  filterQuery?: Record<string, string>;
  navigateTo?: string;
  isLoading: boolean;
}

const SummaryCardStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  flexDirection: 'column',
  padding: 12,
  gap: 10,
  width: 152,
});

const StyledCard = styled(Card)(({theme}) => ({...SummaryCardStyles(theme)}));

const StyledCardAction = styled(ButtonBase)(({theme}) => ({
  ...SummaryCardStyles(theme),
}));

export function EmpowerMeCardSummary({
  title,
  value,
  filterQuery,
  navigateTo,
  isLoading,
}: EmpowerMeCardSummaryProps) {
  const navigate = useNavigate();

  const handleNavigateTo = useCallback(() => {
    if (navigateTo != null) {
      const filterQueries: string[] = [];

      if (filterQuery != null) {
        Object.keys(filterQuery).forEach((key) => {
          filterQueries.push(`${key}=${filterQuery[key]}`);
        });
      }

      navigate({
        pathname: navigateTo,
        search: filterQueries.length > 0 ? filterQueries.join('&') : undefined,
      });
    }
  }, [filterQuery, navigate, navigateTo]);

  if (navigateTo != null) {
    return (
      <StyledCardAction onClick={handleNavigateTo}>
        <Typography align="left" variant="body2">
          {title}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isLoading ? (
            <Skeleton height={30} width={50} />
          ) : (
            <Typography align="left" variant="h6">
              {value ?? '-'}
            </Typography>
          )}
          <ArrowForward />
        </Stack>
      </StyledCardAction>
    );
  }

  return (
    <StyledCard>
      <Typography align="left" variant="body2">
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton height={30} width={50} />
      ) : (
        <Typography align="left" variant="h6">
          {value ?? '-'}
        </Typography>
      )}
    </StyledCard>
  );
}
