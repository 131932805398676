import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {Loader} from '../../common/components/Loader';
import {addShouldNotForwardProps} from '../../common/utils/addShouldNotForwardProps';

interface StyleHomeCardProps {
  $background: string;
}

const StyleHomeCard = styled(Box, {
  shouldForwardProp: addShouldNotForwardProps('$background'),
})<StyleHomeCardProps>(({theme, $background}) => ({
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  padding: 12,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: $background,
  color: '#fff',
  zIndex: 0,
  width: '250px',
  height: '250px',
}));

interface StyleHomeCardImageContainerProps {
  $didLoad: boolean;
  $smallCoverUrl: string;
}

const StyleHomeCardImage = styled(ButtonBase, {
  shouldForwardProp: addShouldNotForwardProps('$didLoad', '$smallCoverUrl'),
})<StyleHomeCardImageContainerProps>(({theme, $didLoad, $smallCoverUrl}) => ({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  zIndex: 1,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundImage: `url(${$smallCoverUrl})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    opacity: $didLoad ? 1 : 0,
    position: 'relative',
  },
  transform: 'translate(-2px, -2px)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translate(-5px, -5px)',
    transition: 'transform 0.3s ease-in-out',
  },
}));

interface HomeCardProps {
  cover: string;
  smallCoverUrl: string;
  title: string;
  background: string;
  path: string;
}

export function HomeCard({
  cover,
  title,
  smallCoverUrl,
  background,
  path,
}: HomeCardProps) {
  const navigate = useNavigate();
  const [didLoadImage, setDidLoadImage] = useState(false);

  const onDidLoadImage = useCallback(() => {
    setDidLoadImage(true);
  }, []);

  const handleGoToRoute = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <StyleHomeCard onClick={handleGoToRoute} $background={background}>
      <StyleHomeCardImage
        $didLoad={didLoadImage}
        $smallCoverUrl={smallCoverUrl}
      >
        <img src={cover} alt={title} onLoad={onDidLoadImage} />
      </StyleHomeCardImage>
      <Loader loading={!didLoadImage} />
      <Typography variant="h6" zIndex={2}>
        {title}
      </Typography>
    </StyleHomeCard>
  );
}
