import {useMemo} from 'react';
import {useWatch} from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import {type OrganisationGroupMinimalDto} from '@onroadvantage/optix-safeandsound-openapi';
import {useQueries} from '@tanstack/react-query';
import {object, string} from 'zod';

import {organisationGroupApi} from '../../../api';
import {OptixForm, OptixSubmit} from '../../../ui-lib/components';
import {type OptixAutocompleteOption} from '../../../ui-lib/components/autocomplete/OptixAutocomplete';
import {OptixAutocompleteHook} from '../../../ui-lib/components/form/components/autocomplete';
import {ORGANISATION_GROUP_NAMES_QUERY_KEY} from '../../common/constants/ORGANISATION_GROUP_NAMES_QUERY_KEY';
import {useOrganisationData} from '../../common/hooks/useOrganisationData';
import {useOrganisationStore} from '../../common/stores/organisationStore';

const schema = object({
  organisation: object({
    value: string(),
    label: string(),
  }),
  organisationGroup: object({
    value: string(),
    label: string(),
  }),
});

interface SelectOrganisationModelProps {
  open: boolean;
  onClose?: () => void;
}

export type OrganisationGroupsByOrganisations = Map<string, string[]>;

export function SelectOrganisationModel({
  open,
  onClose,
}: SelectOrganisationModelProps) {
  const {
    organisationId,
    organisationName,
    organisationGroupId,
    organisationGroupName,
  } = useOrganisationStore();
  const [
    {data: organisationOptions},
    {data: organisationGroupsByOrganisations},
  ] = useOrganisationData(
    {
      select: (organisations) =>
        organisations.map((organisation) => ({
          value: organisation.id,
          label: organisation.name,
        })),
    },
    {
      select: (organisationGroupIds) => {
        const map = new Map<string, string[]>();
        for (const organisationGroup of organisationGroupIds.organisationGroups) {
          map.set(
            organisationGroup.organisationId,
            organisationGroup.organisationGroupsIds,
          );
        }
        return map;
      },
    },
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <OptixForm
        onSubmit={(values) => {
          useOrganisationStore.setState({
            organisationId: values.organisation.value,
            organisationName: values.organisation.label,
            organisationGroupId: values.organisationGroup.value,
            organisationGroupName: values.organisationGroup.label,
          });
          onClose?.();
        }}
        defaultValues={{
          organisation: {value: organisationId, label: organisationName},
          organisationGroup: {
            value: organisationGroupId,
            label: organisationGroupName,
          },
        }}
        validationSchema={schema}
      >
        <Stack p={2} spacing={2}>
          <OptixAutocompleteHook
            name="organisation"
            options={(organisationOptions as OptixAutocompleteOption[]) ?? []}
            label="Organisation"
          />
          <OrganisationGroupAutocomplete
            organisationGroupsByOrganisations={
              organisationGroupsByOrganisations as OrganisationGroupsByOrganisations
            }
          />
          <Stack direction="row" spacing={2}>
            {onClose != null && (
              <Button
                variant="outlined"
                color="inherit"
                onClick={onClose}
                fullWidth
              >
                Cancel
              </Button>
            )}
            <OptixSubmit text="Update" />
          </Stack>
        </Stack>
      </OptixForm>
    </Dialog>
  );
}

interface OrganisationGroupAutocompleteProps {
  organisationGroupsByOrganisations:
    | OrganisationGroupsByOrganisations
    | undefined;
}
function OrganisationGroupAutocomplete({
  organisationGroupsByOrganisations,
}: OrganisationGroupAutocompleteProps) {
  const organisation = useWatch({name: 'organisation'});
  const organisationId = organisation?.value;
  const organisationGroupIds =
    organisationGroupsByOrganisations?.get(organisationId);
  const response = useQueries({
    queries:
      organisationGroupIds?.map((id) => ({
        queryKey: [ORGANISATION_GROUP_NAMES_QUERY_KEY, organisation, id],
        queryFn: async () =>
          await organisationGroupApi.getOrganisationGroupById({
            id,
            organisationId,
          }),
        select: (item: OrganisationGroupMinimalDto) => ({
          value: item.id,
          label: item.name,
        }),
        enabled: organisationId != null,
      })) ?? [],
  });
  const options = useMemo(
    () => response.map((item) => item.data) as OptixAutocompleteOption[],
    [response],
  );
  const isLoading = useMemo(
    () => response.some((item) => item.isLoading),
    [response],
  );
  return (
    <OptixAutocompleteHook
      disabled={organisationId == null || isLoading}
      name="organisationGroup"
      options={options ?? []}
      loading={isLoading}
      label="Organisation Group"
    />
  );
}
