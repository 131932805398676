import {
  Controller,
  type FieldPath,
  type FieldValues,
  useFormContext,
} from 'react-hook-form';

import {
  type OptixTextFieldHookRenderProps,
  OptixTextFieldHookRender,
} from './OptixTextFielHookRender';
import {type OmittedControllerProps} from '../../formTypes';

/** Add all the types together that we would want to provide externally */
export type OptixTextFieldHookProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = OmittedControllerProps<TFieldValues, TName> & OptixTextFieldHookRenderProps;

export function OptixTextFieldHook<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  rules,
  shouldUnregister,
  defaultValue,
  name,
  ...textFieldProps
}: OptixTextFieldHookProps<TFieldValues, TName>) {
  const {control} = useFormContext<TFieldValues>();
  return (
    <Controller<TFieldValues, TName>
      render={(controllerRenderParams) => (
        <OptixTextFieldHookRender<TFieldValues, TName>
          {...textFieldProps}
          {...controllerRenderParams}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
