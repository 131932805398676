import {useMemo} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack, {type StackProps} from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface LoaderProps {
  stackProps?: StackProps;
  loading?: boolean;
  text?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
}

export function Loader({
  stackProps,
  loading = true,
  text,
  size = 'sm',
}: LoaderProps) {
  const convertedSize = useMemo(() => {
    switch (size) {
      case 'lg':
        return 50;
      case 'md':
        return 40;
      case 'sm':
        return 30;
      case 'xs':
        return 25;

      default:
        return 30;
    }
  }, [size]);

  if (!loading) {
    return null;
  }

  return (
    <Stack
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
      {...stackProps}
    >
      <CircularProgress size={convertedSize} />
      {text != null ? <Typography>{text}</Typography> : null}
    </Stack>
  );
}
