import {create} from 'zustand';

import {homeLinks, type NavigationLink} from '../constants/navigation';

interface NavigationStoreState {
  links: NavigationLink[];
}

interface NavigationStoreActions {
  reset: () => void;
}

const authStoreInitialState: NavigationStoreState = {
  links: homeLinks,
};

export type NavigationStore = NavigationStoreState & NavigationStoreActions;

export const useNavigationStore = create<NavigationStore>((setState) => ({
  ...authStoreInitialState,
  reset: () => {
    setState(authStoreInitialState);
  },
}));
