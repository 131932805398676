import {create} from 'zustand';

export interface UserStoreState {
  name?: string | undefined | null;
  email?: string | undefined | null;
  email_verified?: boolean | undefined | null;
  preferred_username?: string | undefined | null;
  given_name?: string | undefined | null;
  family_name?: string | undefined | null;
}

interface UserStoreActions {
  reset: () => void;
  setUserDetails: (userDetails?: UserStoreState | null | undefined) => void;
}

const userUserStoreInitialState: UserStoreState = {};

export type UserStore = UserStoreState & UserStoreActions;

export const useUserStore = create<UserStore>((setState) => ({
  ...userUserStoreInitialState,
  reset: () => {
    setState(userUserStoreInitialState);
  },
  setUserDetails: (userDetails) => {
    setState({
      ...userDetails,
    });
  },
}));
