import {type PropsWithChildren, useCallback} from 'react';
import {type UseFormProps} from 'react-hook-form';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {type FieldValues} from 'react-hook-form/dist/types/fields';

import {OptixButton, OptixForm, type OptixFormProps} from '..';

interface OptixTableEditDialogProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
> {
  isOpen: boolean;
  buttonText?: string;
  onClose: () => void;
  onSubmit?: (data: TFieldValues) => void;
  defaultValues: Required<UseFormProps<TFieldValues>>['defaultValues'];
  title: string;
  FormProps?: Partial<OptixFormProps<TFieldValues, TContext>>;
}

export function OptixModal<
  TFieldValues extends FieldValues,
  TContext = unknown,
>({
  isOpen,
  onClose,
  title,
  defaultValues,
  FormProps,
  children,
  buttonText = 'Ok',
}: PropsWithChildren<OptixTableEditDialogProps<TFieldValues, TContext>>) {
  const handleSubmit = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h5" fontWeight={600}>
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <OptixForm<TFieldValues, TContext>
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          StackProps={{spacing: 2}}
          stack
          {...FormProps}
        >
          {children}
          <Stack pt={1} direction="row" spacing={2}>
            <OptixButton
              variant="outlined"
              color="inherit"
              onClick={onClose}
              fullWidth
            >
              {buttonText}
            </OptixButton>
          </Stack>
        </OptixForm>
      </DialogContent>
    </Dialog>
  );
}
