import {StrictMode} from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './App';

// Capture 0.8 (80%) of all transactions for performance monitoring
// Control which URLs should have distributed tracing enabled with tracePropagationTargets
// Session replay is sampled at 0.1 (10%), but always capture for errors
Sentry.init({
  dsn: 'https://483d5c90b73c95f0340ce5d4a2d2fd0b@o4506069998632960.ingest.us.sentry.io/4508250293665792',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  sampleRate: 0.8,
  tracesSampleRate: 0.8,
  tracePropagationTargets: ['https://ca-safeandsound-prod-euwest-api.agreeableground-a7b2842c.westeurope.azurecontainerapps.io'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.MODE !== 'development',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
