import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {type UUID} from '../types/UUID';

export interface OrganisationStoreState {
  organisationId: UUID;
  organisationName: string;
  organisationGroupId: UUID;
  organisationGroupName: string;
  isOrganisationGroupExisting: boolean;
}

export interface OrganisationStoreAction {
  updateOrgName: (
    organisationName: OrganisationStoreState['organisationName'],
  ) => void;
  updateOrgId: (
    organisationId: OrganisationStoreState['organisationId'],
  ) => void;
  updateOrgGroupName: (
    organisationName: OrganisationStoreState['organisationGroupName'],
  ) => void;
  updateOrgGroupId: (
    organisationId: OrganisationStoreState['organisationGroupId'],
  ) => void;
  updateIsOrganisationGroupExisting: (
    isOrganisationGroupExisting: OrganisationStoreState['isOrganisationGroupExisting'],
  ) => void;
}

export const useOrganisationStore = create(
  persist<OrganisationStoreState & OrganisationStoreAction>(
    (set) => ({
      organisationId: '',
      organisationName: '',
      organisationGroupId: '',
      organisationGroupName: '',
      isOrganisationGroupExisting: false,
      updateOrgGroupName: (organisationGroupName) => {
        set(() => ({organisationGroupName}));
      },
      updateOrgGroupId: (organisationGroupId) => {
        set(() => ({organisationGroupId}));
      },
      updateOrgName: (organisationName) => {
        set(() => ({organisationName}));
      },
      updateOrgId: (organisationId) => {
        set(() => ({organisationId}));
      },
      updateIsOrganisationGroupExisting: (isOrganisationGroupExisting) => {
        set(() => ({isOrganisationGroupExisting}));
      },
    }),
    {name: 'organisationStore'},
  ),
);
