import {HomeCard} from './HomeCard';
import EmpowerMe from '../../../assets/images/empower-me.png';
import EmpowerMeSmall from '../../../assets/images/small/empower-me-small.png';
import {EmpowerMePrimaryColor} from '../../../theme/ThemeEmpowerMePalette';
import {routes} from '../../common/constants/routes';

export default function HomeEmpowerMeCard() {
  return (
    <HomeCard
      cover={EmpowerMe}
      smallCoverUrl={EmpowerMeSmall}
      title="Empower Me"
      background={EmpowerMePrimaryColor.main}
      path={routes.empowerMe.get()}
    />
  );
}
