import {
  VirtualTable,
  type VirtualTableProps,
} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

const StyledOptixTableContainer = styled(VirtualTable.Container)(({theme}) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

export function OptixTableContainer({...props}: VirtualTableProps) {
  return <StyledOptixTableContainer {...props} />;
}
