import {
  type FormEvent,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
} from 'react';
import {
  type FieldValues,
  FormProvider,
  useForm,
  type UseFormProps,
} from 'react-hook-form';

import {zodResolver} from '@hookform/resolvers/zod';
import Card, {type CardProps} from '@mui/material/Card';
import CardContent, {type CardContentProps} from '@mui/material/CardContent';
import CardHeader, {
  type CardHeaderProps as MuiCardHeaderProps,
} from '@mui/material/CardHeader';
import Stack, {type StackProps as MuiStackProps} from '@mui/material/Stack';
import {type ZodSchema} from 'zod';

import {FormStateViewer} from './TemplateFormStateViewer';
import {Loader} from '../../../modules/common/components/Loader';

export interface OptixFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
> extends OptixFormBaseProps<TFieldValues, TContext> {
  debug?: boolean;
  card?: boolean;
  stack?: boolean;
  loading?: boolean;
  StackProps?: MuiStackProps;
  CardProps?: CardProps;
  headerTitle?: ReactNode;
  CardHeaderProps?: MuiCardHeaderProps<'div'>;
  CardContentProps?: CardContentProps;
}

export function OptixForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>({
  loading = false,
  debug = false,
  card = false,
  stack = false,
  CardProps,
  CardHeaderProps,
  CardContentProps,
  StackProps,
  headerTitle,
  children,
  ...props
}: PropsWithChildren<OptixFormProps<TFieldValues, TContext>>) {
  if (card) {
    return (
      <Card {...CardProps}>
        <CardHeader<'div'> title={headerTitle} {...CardHeaderProps} />
        <CardContent {...CardContentProps}>
          {loading ? (
            <Loader />
          ) : (
            <OptixFormBase {...props}>
              {stack ? (
                <Stack {...StackProps}>
                  {children}
                  {debug && <FormStateViewer />}
                </Stack>
              ) : (
                <>
                  {children}
                  {debug && <FormStateViewer />}
                </>
              )}
            </OptixFormBase>
          )}
        </CardContent>
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <OptixFormBase {...props}>
      {stack ? (
        <Stack {...StackProps}>
          {children}
          {debug && <FormStateViewer />}
        </Stack>
      ) : (
        <>
          {children}
          {debug && <FormStateViewer />}
        </>
      )}
    </OptixFormBase>
  );
}

interface OptixFormBaseProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
> extends Partial<Omit<UseFormProps<TFieldValues, TContext>, 'defaultValues'>> {
  onSubmit: (data: TFieldValues) => void;
  defaultValues: Required<
    UseFormProps<TFieldValues, TContext>
  >['defaultValues'];
  validationSchema?: ZodSchema<TFieldValues>;
}

export type FormOnSubmit<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
> = OptixFormBaseProps<TFieldValues, TContext>['onSubmit'];

function OptixFormBase<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>({
  onSubmit,
  validationSchema,
  children,
  ...useFormProps
}: PropsWithChildren<OptixFormBaseProps<TFieldValues, TContext>>) {
  const methods = useForm<TFieldValues, TContext>({
    ...useFormProps,
    resolver:
      validationSchema != null ? zodResolver(validationSchema) : undefined,
  });

  const handlePropSubmit = useCallback(
    (data: TFieldValues) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  const handleFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      await methods.handleSubmit(handlePropSubmit)(event);
    },
    [handlePropSubmit, methods],
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleFormSubmit}
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}
