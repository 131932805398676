import {
  type OrganisationMinimalDto,
  type UserAssignedOrganisationsAndGroupsIdsResponse,
} from '@onroadvantage/optix-safeandsound-openapi';
import {useQuery} from '@tanstack/react-query';

import {organisationApi, userApi} from '../../../api';

export const ORGANISATIONS_QUERY_KEY = 'ORGANISATIONS_QUERY_KEY';
export const ORGANISATION_GROUPS_QUERY_KEY = 'ORGANISATION_GROUPS_QUERY_KEY';

export function useOrganisationData<
  TQueryFnData = OrganisationMinimalDto[],
  KQueryFnData = UserAssignedOrganisationsAndGroupsIdsResponse,
>(
  organisationQueryOptions?: {
    select?: (data: OrganisationMinimalDto[]) => TQueryFnData;
  },
  organisationGroupQueryOptions?: {
    select?: (
      data: UserAssignedOrganisationsAndGroupsIdsResponse,
    ) => KQueryFnData;
  },
) {
  const organisationsQuery = useQuery({
    ...organisationQueryOptions,
    queryKey: [ORGANISATIONS_QUERY_KEY],
    queryFn: async () => await organisationApi.getMe(),
  });

  const organisationGroupQuery = useQuery({
    ...organisationGroupQueryOptions,
    queryKey: [ORGANISATION_GROUPS_QUERY_KEY],
    queryFn: async () =>
      await userApi.getMeOrganisationsAndOrganisationGroupIds(),
  });

  return [organisationsQuery, organisationGroupQuery];
}
