import {lazy} from 'react';
import {type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from './ProtectedRoute';
import {empowerMeViewRoles} from '../modules/common/constants/roles';
import {routes} from '../modules/common/constants/routes';
import {
  type Breadcrumb,
  breadcrumbLoaderFunction,
} from '../modules/common/stores/breadcrumbStore';
import EmpowerMePage from '../modules/empowerMe/pages/EmpowerMe';

const EmpowerMeReasonCodes = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeReasonCodes'),
);

const EmpowerMeBehaviours = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeBehaviours'),
);

const EmpowerMeDrivers = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeDrivers'),
);

const EmpowerMeTraining = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeTraining'),
);

const EmpowerMeUploadUser = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeUploadUser'),
);

const EmpowerMeOrganisations = lazy(
  async () => await import('../modules/empowerMe/pages/EmpowerMeOrganisations'),
);

const EmpowerMeOrganisationGroups = lazy(
  async () =>
    await import('../modules/empowerMe/pages/EmpowerMeOrganisationGroups'),
);

const baseEmpowerMeBreadcrumbs: Breadcrumb[] = [
  {label: 'Empower Me', path: routes.empowerMe.get()},
];

export const empowerMeChildren: RouteObject[] = [
  {
    path: '',
    element: (
      <ProtectedRoute roles={empowerMeViewRoles}>
        <EmpowerMePage />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([{label: 'Empower Me'}]),
  },
  {
    path: routes.empowerMe.events,
    element: (
      <ProtectedRoute roles={['eventactivityreasoncode:read']}>
        <EmpowerMeReasonCodes />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Reason Codes'},
    ]),
  },
  {
    path: routes.empowerMe.drivers,
    element: (
      <ProtectedRoute roles={['driver:read']}>
        <EmpowerMeDrivers />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Drivers'},
    ]),
  },
  {
    path: routes.empowerMe.behaviours,
    element: (
      <ProtectedRoute roles={['eventbehaviour:read']}>
        <EmpowerMeBehaviours />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Behaviours'},
    ]),
  },
  {
    path: routes.empowerMe.training,
    element: (
      <ProtectedRoute roles={['trainingcourse:read']}>
        <EmpowerMeTraining />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Training'},
    ]),
  },
  {
    path: routes.empowerMe.uploadUser,
    element: (
      <ProtectedRoute roles={['uploaduser:write']}>
        <EmpowerMeUploadUser />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Upload User'},
    ]),
  },
  {
    path: routes.empowerMe.organisations,
    element: (
      <ProtectedRoute roles={['organisation:read']}>
        <EmpowerMeOrganisations />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Organisations'},
    ]),
  },
  {
    path: routes.empowerMe.organisationGroups,
    element: (
      <ProtectedRoute roles={['organisationgroup:read']}>
        <EmpowerMeOrganisationGroups />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseEmpowerMeBreadcrumbs,
      {label: 'Organisation Groups'},
    ]),
  },
];
