import InputLabel from '@mui/material/InputLabel';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const StyledOptixInputLabel = styled(InputLabel)(({theme}) => ({
  color: theme.palette.text.primary,
}));

export const StyledOptixTextField = styled(TextField)(({theme}) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.background.paper}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 1px 2px rgba(16, 24, 40, 0.05)',
    },
    fontSize: '16px',
  },
  '& .MuiFormLabel-root': {
    color: theme.palette.text.primary,
  },
}));
