import {type PaletteColor, type PaletteOptions} from '@mui/material/styles';

export const RecruitMePrimaryColor: PaletteColor = {
  main: '#85C2C0',
  light: '#85C2C0',
  dark: '#85C2C0',
  contrastText: '#000',
};

export const ThemeRecruitMePalette: PaletteOptions = {
  primary: RecruitMePrimaryColor,
};
