import {createContext, useContext} from 'react';

import {type OptixTableProps} from './OptixTable.types';

export const OptixTableContext = createContext<OptixTableProps<unknown> | null>(
  null,
);

export const useOptixTableContext = () => {
  const optixTableContext = useContext<OptixTableProps<unknown> | null>(
    OptixTableContext,
  );

  if (optixTableContext == null) {
    throw new Error(
      'useOptixTableContext has to be used within <OptixTableContext.Provider>',
    );
  }

  return optixTableContext;
};
