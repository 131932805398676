import {lazy} from 'react';
import {Outlet, type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from './ProtectedRoute';
import {trainMeViewRoles} from '../modules/common/constants/roles';
import {routes} from '../modules/common/constants/routes';
import {
  type Breadcrumb,
  breadcrumbLoaderFunction,
} from '../modules/common/stores/breadcrumbStore';

const TrainMeCandidates = lazy(
  async () => await import('../modules/trainMe/pages/TrainMeCandidates'),
);
const TrainMeScheduler = lazy(
  async () => await import('../modules/trainMe/pages/TrainMeScheduler'),
);
const TrainMeUploadUser = lazy(
  async () => await import('../modules/trainMe/pages/TrainMeUploadUser'),
);

const baseTrainMeBreadcrumbs: Breadcrumb[] = [
  {label: 'Train Me', path: routes.trainMe.get()},
];

export const trainMeChildren: RouteObject[] = [
  {
    path: '',
    element: (
      <ProtectedRoute roles={trainMeViewRoles}>
        <Outlet />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([{label: 'Train Me'}]),
  },
  {
    path: routes.trainMe.candidates,
    element: (
      <ProtectedRoute roles={[]}>
        <TrainMeCandidates />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseTrainMeBreadcrumbs,
      {label: 'Learners'},
    ]),
  },
  {
    path: routes.trainMe.scheduler,
    element: (
      <ProtectedRoute roles={[]}>
        <TrainMeScheduler />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseTrainMeBreadcrumbs,
      {label: 'Scheduler'},
    ]),
  },
  {
    path: routes.trainMe.uploadUser,
    element: (
      <ProtectedRoute roles={[]}>
        <TrainMeUploadUser />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseTrainMeBreadcrumbs,
      {label: 'Learner Upload'},
    ]),
  },
];
