import {type AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import {Loader} from '../../../modules/common/components/Loader';

interface OptixAutocompleteRenderTextFieldProps
  extends AutocompleteRenderInputParams {
  loading?: boolean;
  placeholder?: string;
  label?: string;
}

export function OptixAutocompleteRenderTextField({
  loading = false,
  placeholder,
  InputProps,
  ...params
}: OptixAutocompleteRenderTextFieldProps) {
  return (
    <TextField
      {...params}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {loading ? <Loader size="xs" /> : null}
            {InputProps.endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
}
