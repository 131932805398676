import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {useUserStore} from '../../common/stores/userStore';

export default function Profile() {
  const user = useUserStore();

  return (
    <List>
      <ListItem>
        <ListItemText primary="Name" secondary={user.name} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Preferred Username"
          secondary={user.preferred_username}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Family Name" secondary={user.family_name} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Given Name" secondary={user.given_name} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Email" secondary={user.email} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Email Verified"
          secondary={user.email_verified === true ? 'Verified' : 'Not Verified'}
        />
      </ListItem>
    </List>
  );
}
