import {useEffect, useMemo} from 'react';
import {RouterProvider} from 'react-router-dom';
import {toast} from 'react-toastify';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {AuthWrapper} from './AuthWrapper';
import {Toastify} from './modules/common/components/Toastify';
import {usePreferencesStore} from './modules/common/stores/preferencesStore';
import {router} from './router/router';
import {ThemeBase} from './theme/ThemeBase';
import {ThemeDarkPalette} from './theme/ThemeDarkPalette';
import {ThemeLightPalette} from './theme/ThemeLightPalette';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {retry: 2},
    mutations: {
      onError: (error) => toast.error(error.message ?? 'Mutation failed'),
    },
  },
});

function App() {
  const themeMode = usePreferencesStore((state) => state.themeMode);
  useEffect(() => {
    const el = document.querySelector('#splash-screen');
    el?.remove();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={useMemo(() => {
          const paletteMode =
            themeMode === 'dark' ? ThemeDarkPalette : ThemeLightPalette;
          return createTheme({
            ...ThemeBase,
            palette: {...ThemeBase.palette, ...paletteMode},
          });
        }, [themeMode])}
      >
        <AuthWrapper>
          <RouterProvider router={router} />
          <Toastify />
        </AuthWrapper>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
