import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import {useAuth} from 'oidc-react';

import {OptixButton} from '../../../ui-lib/components';
import {Loader} from '../../common/components/Loader';
import {config} from '../../common/services/configService';

function AuthRedirectUnauthorized() {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((curr) => curr + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Stack
      flex={1}
      flexGrow={1}
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <Loader size="lg" text="Validating..." />
      <Zoom in={seconds > 3}>
        <Stack>
          <OptixButton
            size="large"
            onClick={() => {
              window.location.replace(config.sso.loginUri);
            }}
          >
            Go to login
          </OptixButton>
        </Stack>
      </Zoom>
    </Stack>
  );
}

export function AuthRedirect() {
  const {userData, isLoading} = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (userData?.access_token != null && userData.expired !== true) {
      navigate('/');
    }
  }, [navigate, userData?.access_token, userData?.expired]);

  if (isLoading) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <Loader size="lg" text="Validating" />
      </Stack>
    );
  }

  if (userData?.access_token != null && userData.expired !== true) {
    return (
      <Stack
        flex={1}
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          navigate('/');
        }}
      >
        <Typography variant="h2">
          Logged in! Welcome back {userData.profile.name}.
        </Typography>
      </Stack>
    );
  }

  return <AuthRedirectUnauthorized />;
}
