import {ToastContainer} from 'react-toastify';

import {useTheme} from '@mui/material/styles';

import {StyledToastContainer} from './Toastify.style';

import 'react-toastify/dist/ReactToastify.css';

export function Toastify() {
  const {palette} = useTheme();

  return (
    <StyledToastContainer>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={2}
        closeButton={false}
        theme={palette.mode}
      />
    </StyledToastContainer>
  );
}
