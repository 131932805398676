import {type ReactNode} from 'react';
import {Outlet} from 'react-router-dom';

import InvalidRole from '../modules/common/components/InvalidRole';
import {type Role} from '../modules/common/constants/roles';
import {useAuthStore} from '../modules/common/stores/authStore';

interface ProtectedRouteProps {
  roles: Role[];
  children?: ReactNode;
}

export function ProtectedRoute({roles, children}: ProtectedRouteProps) {
  const checkRoles = useAuthStore((state) => state.checkRoles);

  if (!checkRoles(...roles)) {
    return <InvalidRole role={roles.join(', ')} />;
  }

  if (children != null) {
    return children;
  }

  return <Outlet />;
}
