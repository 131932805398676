import {useMemo} from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  EmpowerMeCardStat,
  type EmpowerMeCardStatProps,
} from './EmpowerMeCardStat';

interface EmpowerMeCardStatsProps {
  title: string;
  subTitle?: string;
  stats: EmpowerMeCardStatProps[];
}

const StyledCard = styled(Card)(({theme}) => ({
  height: '400px',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  flexDirection: 'column',
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 12,
  gap: 10,
  flex: 1,
  zIndex: 0,
}));

const StyledCardHeader = styled(Stack)(({theme}) => ({
  top: 0,
  left: 0,
  zIndex: 2,
  paddingTop: 12,
  position: 'sticky',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'stretch',
  backgroundColor: '#fff',
}));

const StyledDivider = styled(Divider)(({theme}) => ({
  marginY: theme.spacing(1),
}));

export function EmpowerMeCardStats({
  title,
  subTitle,
  stats,
}: EmpowerMeCardStatsProps) {
  return (
    <StyledCard>
      <StyledCardHeader>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="subtitle1" color={'#0143A2'}>
            {subTitle}
          </Typography>
        </Stack>
        <StyledDivider flexItem />
      </StyledCardHeader>
      <Stack spacing={2} flex={1} zIndex={0}>
        {stats?.map((stat) => <EmpowerMeCardStat key={stat.name} {...stat} />)}
      </Stack>
    </StyledCard>
  );
}

interface EmpowerMeCardStatsLoaderProps {
  title: string;
  skeletonsCount: number;
}

export function EmpowerMeCardStatsLoader({
  title,
  skeletonsCount,
}: EmpowerMeCardStatsLoaderProps) {
  const skeletonArray = useMemo(
    () =>
      Array.from({length: skeletonsCount}, (_, index) => (
        <Skeleton key={index} height={50} width="100%" />
      )),
    [skeletonsCount],
  );
  return (
    <StyledCard>
      <StyledCardHeader>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>
        <StyledDivider flexItem />
      </StyledCardHeader>
      <Stack spacing={2} flex={1} zIndex={0}>
        {skeletonArray}
      </Stack>
    </StyledCard>
  );
}
