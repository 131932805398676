import {createBrowserRouter, Outlet, redirect} from 'react-router-dom';

import {empowerMeChildren} from './router.empowerMe';
import {recruitMeChildren} from './router.recruitMe';
import {trainMeChildren} from './router.trainMe';
import {AuthRedirect} from '../modules/auth/pages/AuthRedirect';
import {AppShell} from '../modules/common/components/appShell/AppShell';
import {ErrorBoundary} from '../modules/common/components/ErrorBoundary';
import {
  empowerMeLinks,
  homeLinks,
  recruitMeLinks,
  trainMeLinks,
} from '../modules/common/constants/navigation';
import {routes} from '../modules/common/constants/routes';
import {useAuthStore} from '../modules/common/stores/authStore';
import {useBreadcrumbStore} from '../modules/common/stores/breadcrumbStore';
import {useNavigationStore} from '../modules/common/stores/navigationStore';
import {useThemeStore} from '../modules/common/stores/themeStore';
import {Home} from '../modules/home/pages/Home';
import Profile from '../modules/profile/pages/Profile';
import {OrganisationWrapper} from '../OrganisationWrapper';
import {NestedThemeProvider} from '../theme/NestedThemeProvider';
import {ThemeEmpowerMePalette} from '../theme/ThemeEmpowerMePalette';
import {ThemeRecruitMePalette} from '../theme/ThemeRecruitMePalette';
import {ThemeTrainMePalette} from '../theme/ThemeTrainMePalette';

const resetRouteLoaders = () => {
  useBreadcrumbStore.setState({breadcrumbs: []});
  useNavigationStore.setState({links: homeLinks});
  useThemeStore.setState({palette: null});
  return null;
};

export const router = createBrowserRouter([
  {
    path: routes.landing,
    element: (
      <NestedThemeProvider>
        <AppShell>
          <OrganisationWrapper>
            <Outlet />
          </OrganisationWrapper>
        </AppShell>
      </NestedThemeProvider>
    ),
    ErrorBoundary,
    loader: () => {
      const {expiresAt} = useAuthStore.getState();
      const {token} = useAuthStore.getState();
      if (token == null || (expiresAt != null && expiresAt <= new Date())) {
        return redirect('/login');
      }
      return null;
    },
    children: [
      {
        path: '',
        element: <Home />,
        loader: resetRouteLoaders,
      },
      {
        path: routes.empowerMe.get(),
        element: <Outlet />,
        children: empowerMeChildren,
        loader: () => {
          useThemeStore.setState({palette: ThemeEmpowerMePalette});
          useNavigationStore.setState({links: empowerMeLinks});
          return null;
        },
      },
      {
        path: routes.recruitMe.get(),
        element: <Outlet />,
        children: recruitMeChildren,
        loader: () => {
          useThemeStore.setState({palette: ThemeRecruitMePalette});
          useNavigationStore.setState({links: recruitMeLinks});
          return null;
        },
      },
      {
        path: routes.trainMe.get(),
        element: <Outlet />,
        children: trainMeChildren,
        loader: () => {
          useThemeStore.setState({palette: ThemeTrainMePalette});
          useNavigationStore.setState({links: trainMeLinks});
          return null;
        },
      },
      {
        path: '/profile',
        element: <Profile />,
        loader: () => {
          useBreadcrumbStore.setState({breadcrumbs: [{label: 'Profile'}]});
          useNavigationStore.setState({links: homeLinks});
          useThemeStore.setState({palette: null});
          return null;
        },
      },
    ],
  },
  {
    path: routes.login,
    element: <AuthRedirect />,
    ErrorBoundary,
    loader: resetRouteLoaders,
  },
  {
    path: routes.authRedirect,
    element: <AuthRedirect />,
    ErrorBoundary,
    loader: resetRouteLoaders,
  },
  {
    path: routes.notFound,
    lazy: async () => await import('./NotFound'),
    ErrorBoundary,
    loader: resetRouteLoaders,
  },
]);
