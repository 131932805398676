import {PagingPanel} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

const StyledOptixTablePagingContainer = styled(PagingPanel.Container)(
  ({theme}) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid #E9E9E9`,
    boxShadow: 'none',
    padding: '0 16px !important',
    marginTop: 16,
  }),
);

export function OptixTablePagingContainer({
  ...props
}: PagingPanel.ContainerProps) {
  return <StyledOptixTablePagingContainer {...props} />;
}
