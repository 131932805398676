import {type ReactNode, useMemo} from 'react';

import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';

import {useThemeStore} from '../modules/common/stores/themeStore';

interface NestedThemeProviderProps {
  children: ReactNode;
}
export function NestedThemeProvider({children}: NestedThemeProviderProps) {
  const theme = useTheme();
  const palette = useThemeStore((state) => state.palette);

  const memoizedTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {...theme.palette, ...palette},
      }),
    [palette, theme],
  );

  if (palette == null) {
    return children;
  }

  return <ThemeProvider theme={memoizedTheme}>{children}</ThemeProvider>;
}
