import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

interface SidNavContainerProps {
  $isExpanded: boolean;
}

export const SidNavContainer = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps('$isExpanded'),
})<SidNavContainerProps>(({theme, $isExpanded}) => ({
  width: $isExpanded ? '17rem' : '4rem',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  justifyContent: 'center',
}));

export const SidNavContent = styled(Stack)(() => ({
  flex: 1,
  position: 'relative',
}));

export const LogoImageContainer = styled(ButtonBase)(({theme}) => ({
  cursor: 'pointer',
  width: '100%',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& > img': {
    width: 'clamp(100px, 90%, 200px)',
  },
}));

export const LogoImageSmallContainer = styled(ButtonBase)(({theme}) => ({
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.35, 0),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > img': {
    width: 22,
  },
}));
