import {HomeCard} from './HomeCard';
import RecruitMe from '../../../assets/images/recruit-me.jpeg';
import EmpowerMeSmall from '../../../assets/images/small/empower-me-small.png';
import {RecruitMePrimaryColor} from '../../../theme/ThemeRecruitMePalette';
import {routes} from '../../common/constants/routes';

export default function HomeEmpowerMeCard() {
  return (
    <HomeCard
      cover={RecruitMe}
      smallCoverUrl={EmpowerMeSmall}
      title="Recruit Me"
      background={RecruitMePrimaryColor.main}
      path={routes.recruitMe.assessmentChat}
    />
  );
}
