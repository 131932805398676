import {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import {type EmpowerMeSummaryDto} from '@onroadvantage/optix-safeandsound-openapi';

import {empowerMeApi} from '../../../api';
import {useOrganisationStore} from '../../common/stores/organisationStore';
import {isValidUUID, type UUID} from '../../common/types/UUID';

type LoadSummaryStatus = 'idle' | 'loading' | 'success' | 'error';

interface EmpowerMeSummaryDtoWithTotalEventCount
  extends Omit<EmpowerMeSummaryDto, 'totalEventCount'> {
  totalEventCount?: EmpowerMeSummaryDto['totalEventCount'] & {
    total: number | null;
  };
}

interface SummaryParams {
  organisationId: UUID;
  organisationGroupId?: UUID;
}

export const useLoadEmpowerMeSummary = () => {
  const organisationId = useOrganisationStore((state) => state.organisationId);
  const organisationGroupId = useOrganisationStore(
    (state) => state.organisationGroupId,
  );

  const [loadSummaryStatus, setLoadSummaryStatus] =
    useState<LoadSummaryStatus>('idle');
  const [empowerMeSummary, setEmpowerMeSummary] =
    useState<EmpowerMeSummaryDtoWithTotalEventCount | null>(null);

  const loadSummary = useCallback(async () => {
    setLoadSummaryStatus('loading');
    if (!isValidUUID(organisationId)) {
      return;
    }
    try {
      const summaryParams: SummaryParams = {
        organisationId,
      };

      if (organisationGroupId !== '' && isValidUUID(organisationGroupId)) {
        summaryParams.organisationGroupId = organisationGroupId;
      }

      const response = await empowerMeApi.getLandingSummary(summaryParams);

      let totalEvents = 0;

      if (response.totalEventCount?.openCount != null) {
        totalEvents += response.totalEventCount.openCount;
      }
      if (response.totalEventCount?.acknowledgedCount != null) {
        totalEvents += response.totalEventCount.acknowledgedCount;
      }
      if (response.totalEventCount?.disputedCount != null) {
        totalEvents += response.totalEventCount.disputedCount;
      }

      setEmpowerMeSummary({
        ...response,
        totalEventCount: {
          ...(response.totalEventCount ?? {}),
          total: totalEvents,
        },
      });
      setLoadSummaryStatus('success');
    } catch (e) {
      setLoadSummaryStatus('error');
      toast('Failed to load summary', {type: 'error'});
    }
  }, [organisationId, organisationGroupId]);

  useEffect(() => {
    void loadSummary();
  }, [loadSummary]);

  return {loadSummaryStatus, empowerMeSummary};
};
