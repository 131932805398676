import {string, type z} from 'zod';

const uuidType = string().uuid({message: 'Invalid UUID'});

export type UUID = z.infer<typeof uuidType>;

export const isValidUUID = (value: unknown): value is UUID => {
  try {
    const parsedUUID = uuidType.parse(value);
    return value === parsedUUID;
  } catch {
    return false;
  }
};
