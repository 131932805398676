import {type ReactNode} from 'react';

import Button, {type ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

import {usePreferencesStore} from '../../stores/preferencesStore';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

export const StyledButton = styled(Button, {
  shouldForwardProp: addShouldNotForwardProps('$isActive', '$isExpanded'),
})<{$isActive: boolean; $isExpanded?: boolean}>(
  ({theme, $isActive, $isExpanded}) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.2),
    gap: 12,
    ...($isExpanded === false
      ? {width: 'fit-content', minWidth: 'fit-content', maxWidth: 'fit-content'}
      : {}),
    width: 'fit-content',
    minWidth: $isExpanded === true ? '100%' : 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    textAlign: 'left',
    '& .MuiButton-startIcon': {
      margin: 0,
      width: 24,
    },
    color: $isActive ? theme.palette.primary.main : theme.palette.text.primary,
  }),
);

export interface OptixNavigationButtonProps extends ButtonProps {
  isActive: boolean;
  icon: ReactNode;
  name: string;
}

export function OptixNavigationButton({
  isActive,
  icon,
  name,
  ...buttonProps
}: OptixNavigationButtonProps) {
  const sidePanelExpanded = usePreferencesStore(
    (state) => state.sidePanelExpanded,
  );

  if (!sidePanelExpanded) {
    return (
      <StyledButton $isActive={isActive} fullWidth={false} {...buttonProps}>
        {icon}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      $isExpanded
      startIcon={icon}
      $isActive={isActive}
      fullWidth
      {...buttonProps}
    >
      {name}
    </StyledButton>
  );
}
