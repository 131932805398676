import Grid2 from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import {routes} from '../../common/constants/routes';
import {
  EmpowerMeCardStats,
  EmpowerMeCardStatsLoader,
} from '../components/EmpowerMeCardStats';
import {EmpowerMeCardSummary} from '../components/EmpowerMeCardSummary';
import {useLoadEmpowerMeSummary} from '../hooks/useLoadEmpowerMeSummary';

const categoryLabels: Record<string, string> = {
  'Category 1': 'Face to Face',
  'Category 2': 'Face to Face',
  'Category 3': 'FYI',
  'Category 4': 'Resolved',
};

export default function EmpowerMe() {
  const {loadSummaryStatus, empowerMeSummary} = useLoadEmpowerMeSummary();

  return (
    <Stack flex={1} gap={2}>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.drivers}
          title="Total Drivers"
          value={empowerMeSummary?.totalDriverCount}
          isLoading={loadSummaryStatus === 'loading'}
        />
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.drivers}
          title="Drivers with Events"
          value={empowerMeSummary?.driversWithEventsCount}
          isLoading={loadSummaryStatus === 'loading'}
        />
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.events}
          title="Total Events"
          value={empowerMeSummary?.totalEventCount?.total}
          isLoading={loadSummaryStatus === 'loading'}
        />
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.events}
          title="Self Coachable Events"
          filterQuery={{eventStatus: 'Open'}}
          value={empowerMeSummary?.totalEventCount?.openCount}
          isLoading={loadSummaryStatus === 'loading'}
        />
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.events}
          title="Acknowledged Events"
          filterQuery={{eventStatus: 'Acknowledged'}}
          value={empowerMeSummary?.totalEventCount?.acknowledgedCount}
          isLoading={loadSummaryStatus === 'loading'}
        />
        <EmpowerMeCardSummary
          navigateTo={routes.empowerMe.events}
          title="Disputed Events"
          filterQuery={{eventStatus: 'Disputed'}}
          value={empowerMeSummary?.totalEventCount?.disputedCount}
          isLoading={loadSummaryStatus === 'loading'}
        />
      </Stack>
      <Grid2 spacing={2} alignItems="stretch" container>
        {loadSummaryStatus === 'loading' ? (
          <>
            <Grid2 size={{xs: 12, md: 6}}>
              <EmpowerMeCardStatsLoader title="Category 1" skeletonsCount={3} />
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}>
              <EmpowerMeCardStatsLoader title="Category 2" skeletonsCount={5} />
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}>
              <EmpowerMeCardStatsLoader title="Category 3" skeletonsCount={5} />
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}>
              <EmpowerMeCardStatsLoader title="Category 4" skeletonsCount={4} />
            </Grid2>
          </>
        ) : (
          empowerMeSummary?.categorisedEventBehaviours
            .filter((item) => item?.categoryName !== undefined)
            .sort((a, b) =>
              (a?.categoryName ?? '') > (b?.categoryName ?? '') ? 1 : -1,
            )
            .map(({categoryName, eventBehaviours}) => {
              // Set driverVisible based on the condition
              const updatedEventBehaviours = eventBehaviours.map(
                ({name, driverVisible}) => ({
                  name,
                  isDriverActive: driverVisible,
                }),
              );

              const singleCategoryName = Array.isArray(categoryName)
                ? categoryName[0]
                : categoryName;

              return (
                <Grid2
                  size={{xs: 12, md: 6}}
                  key={singleCategoryName.toString()}
                >
                  <EmpowerMeCardStats
                    title={singleCategoryName}
                    subTitle={categoryLabels[singleCategoryName] ?? '-'}
                    stats={updatedEventBehaviours}
                  />
                </Grid2>
              );
            })
        )}
      </Grid2>
    </Stack>
  );
}
