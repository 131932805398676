const {
  MODE,
  VITE_APP_SERVER_URI,
  VITE_APP_SSO_CLIENT_ID,
  VITE_APP_SSO_HOST,
  VITE_APP_SSO_REALM,
  VITE_APP_SSO_API_CLIENT_ID,
} = import.meta.env;

function getMetaTagContent(tagName: string): string | null {
  const metas = document.getElementsByTagName('meta');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i];
    if (meta.getAttribute('property') === tagName) {
      return meta.getAttribute('content');
    }
  }

  return null;
}

let serverUri: string | null;
let ssoClientId: string | null;
let ssoApiClientId: string | null;
let ssoHost: string | null;
let ssoRealm: string | null;

if (MODE === 'development') {
  serverUri = VITE_APP_SERVER_URI;
  ssoClientId = VITE_APP_SSO_CLIENT_ID;
  ssoApiClientId = VITE_APP_SSO_API_CLIENT_ID;
  ssoHost = VITE_APP_SSO_HOST;
  ssoRealm = VITE_APP_SSO_REALM;
} else {
  serverUri = getMetaTagContent('app:server-uri') ?? 'http://localhost:5000';
  ssoHost = getMetaTagContent('app:sso-host');
  ssoRealm = getMetaTagContent('app:sso-realm');
  ssoClientId = getMetaTagContent('app:sso-client-id');
  ssoApiClientId = getMetaTagContent('app:sso-api-client-id');
}

export const config = {
  serverUri,
  sso: {
    host: ssoHost,
    realm: ssoRealm,
    clientId: ssoClientId,
    apiClientId: ssoApiClientId,
    get loginUri() {
      return `${this.host}/auth/realms/${this.realm}`;
    },
    get appsUrl() {
      return `${this.host}/auth/realms/${this.realm}/account/applications`;
    },
  },
};
