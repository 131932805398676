import {Toolbar} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

const StyledOptixToolbar = styled(Toolbar.Root)(() => ({
  borderBottom: 'none !important',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 0 16px !important',
  gap: 16,
  minHeight: '0 !important',
}));

export function OptixTableToolbarRoot({children, ...props}: Toolbar.RootProps) {
  return <StyledOptixToolbar {...props}>{children}</StyledOptixToolbar>;
}
