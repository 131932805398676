import {TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

const StyledOptixTableHeaderCell = styled(TableHeaderRow.Cell)(({theme}) => ({
  color: theme.palette.text.primary,
}));

export function OptixTableHeaderCell({
  children,
  ...props
}: TableHeaderRow.CellProps) {
  return (
    <StyledOptixTableHeaderCell {...props}>
      {children}
    </StyledOptixTableHeaderCell>
  );
}
