import {type ThemeOptions} from '@mui/material/styles';

import {ThemeLightPalette} from './ThemeLightPalette';

export const ThemeBase: ThemeOptions = {
  spacing: 10,
  components: {
    MuiButton: {
      defaultProps: {size: 'medium', color: 'primary'},
      styleOverrides: {
        root: {textTransform: 'none', whiteSpace: 'nowrap', borderRadius: 8},
        sizeMedium: {fontSize: 16},
      },
    },
    MuiChip: {styleOverrides: {root: {borderRadius: 8}}},
    MuiToggleButton: {styleOverrides: {root: {textTransform: 'none'}}},
    MuiOutlinedInput: {styleOverrides: {root: {borderRadius: 8}}},
  },
  typography: {
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    fontSize: 13,
  },
  shape: {borderRadius: 16},
  palette: {
    mode: 'light',
    common: {black: '#010101', white: '#fefefe'},
    primary: {
      light: '#F7B27A',
      main: '#F26722',
      dark: '#B93815',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4d4d4d',
      main: '#4d4d4d',
      dark: '#4d4d4d',
      contrastText: '#fff',
    },
    success: {
      light: '#56B88A',
      main: '#00944F',
      dark: '#006134',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFB657',
      main: '#FF9000',
      dark: '#A85F00',
      contrastText: '#fff',
    },
    error: {
      light: '#F06F66',
      main: '#E9291C',
      dark: '#9B1B12',
      contrastText: '#fff',
    },
    info: {
      light: '#6A9499',
      main: '#064C55',
      dark: '#053D44',
      contrastText: '#fff',
    },
    ...ThemeLightPalette,
  },
};
