import {type PaletteMode} from '@mui/material/styles';
import {create, type StateCreator} from 'zustand';
import {persist} from 'zustand/middleware';

interface PreferencesStoreState {
  themeMode: PaletteMode;
  sidePanelExpanded: boolean;
}

interface PreferencesStoreActions {
  reset: () => void;
  toggleThemeMode: () => void;
  toggleSidePanelExpanded: () => void;
  setThemeMode: (themeMode: PaletteMode) => void;
  setSidePanelExpanded: (sidePanelExpanded: boolean) => void;
}

const preferencesStoreInitialState: PreferencesStoreState = {
  themeMode: 'light',
  sidePanelExpanded: true,
};

export type PreferencesStore = PreferencesStoreState & PreferencesStoreActions;

const preferencesStoreHandler: StateCreator<PreferencesStore> = (setState) => ({
  ...preferencesStoreInitialState,
  reset: () => {
    setState(preferencesStoreInitialState);
  },
  setThemeMode: (themeMode) => {
    setState({themeMode});
  },
  toggleThemeMode: () => {
    setState((state) => ({
      themeMode: state.themeMode === 'light' ? 'dark' : 'light',
    }));
  },
  setSidePanelExpanded: (sidePanelExpanded) => {
    setState({sidePanelExpanded});
  },
  toggleSidePanelExpanded: () => {
    setState((state) => ({sidePanelExpanded: !state.sidePanelExpanded}));
  },
});

const PreferencesStoreMiddleWare = (f: StateCreator<PreferencesStore>) =>
  persist(f, {name: 'preferences'});

export const usePreferencesStore = create<PreferencesStore>()(
  PreferencesStoreMiddleWare(preferencesStoreHandler),
);
