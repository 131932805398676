import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  empowerMeViewRoles,
  recruitMeViewRoles,
  trainMeViewRoles,
} from '../../common/constants/roles';
import {useAuthStore} from '../../common/stores/authStore';
import {useOrganisationStore} from '../../common/stores/organisationStore';
import {useUserStore} from '../../common/stores/userStore';
import {type UUID} from '../../common/types/UUID';
import HomeEmpowerMeCard from '../components/HomeEmpowerMeCard';
import HomeRecruitMeCard from '../components/HomeRecruitMeCard';
import HomeTrainMeCard from '../components/HomeTrainMeCard';

export interface OrganisationGroupDetails {
  organisationId: UUID;
  organisationGroupsIds: UUID[];
}

export interface OrganisationDetailsInformation {
  OrganisationGroupDetails: OrganisationGroupDetails;
}

export function Home() {
  const checkRoles = useAuthStore((state) => state.checkRoles);
  const organisationName = useOrganisationStore(
    (state) => state.organisationName,
  );
  const organisationGroupName = useOrganisationStore(
    (state) => state.organisationGroupName,
  );

  const name = useUserStore((state) => state.name);
  return (
    <>
      <Typography variant="h4">Welcome Home, {name ?? ''}!</Typography>
      {organisationName != null && organisationName !== '' && (
        <Typography color="text.secondary" mb={2}>
          You have the <strong>{organisationName}</strong> organisation and{' '}
          <strong>{organisationGroupName}</strong> group selected.
        </Typography>
      )}
      <Stack direction="row" gap={3} px={1} flexWrap="wrap">
        {checkRoles(...empowerMeViewRoles) && <HomeEmpowerMeCard />}
        {checkRoles(...recruitMeViewRoles) && <HomeRecruitMeCard />}
        {checkRoles(...trainMeViewRoles) && <HomeTrainMeCard />}
      </Stack>
    </>
  );
}
