import {lazy} from 'react';
import {type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from './ProtectedRoute';
import {recruitMeViewRoles} from '../modules/common/constants/roles';
import {routes} from '../modules/common/constants/routes';
import {
  type Breadcrumb,
  breadcrumbLoaderFunction,
} from '../modules/common/stores/breadcrumbStore';
import RecruitMePage from '../modules/recruitMe/pages/RecruitMe';

const RecruitMeAssessment = lazy(
  async () =>
    await import('../modules/recruitMe/pages/RecruitMeAssessmentChat'),
);
const RecruitMeFeedback = lazy(
  async () => await import('../modules/recruitMe/pages/RecruitMeFeedbackChat'),
);
const RecruitMeCandidates = lazy(
  async () => await import('../modules/recruitMe/pages/RecruitMeCandidates'),
);

const baseRecruitMeBreadcrumbs: Breadcrumb[] = [
  {label: 'Recruit Me', path: routes.recruitMe.get()},
];

export const recruitMeChildren: RouteObject[] = [
  {
    path: '',
    element: (
      <ProtectedRoute roles={recruitMeViewRoles}>
        <RecruitMePage />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([{label: 'Recruit Me'}]),
  },
  {
    path: routes.recruitMe.candidates,
    element: (
      <ProtectedRoute roles={['recruitmecandidate:read']}>
        <RecruitMeCandidates />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseRecruitMeBreadcrumbs,
      {label: 'Candidates'},
    ]),
  },
  {
    path: routes.recruitMe.assessmentChat,
    element: (
      <ProtectedRoute roles={['recruitmeassessments:write']}>
        <RecruitMeAssessment />
      </ProtectedRoute>
    ),
    loader: breadcrumbLoaderFunction([
      ...baseRecruitMeBreadcrumbs,
      {label: 'Assessment Chat'},
    ]),
  },
  {
    path: routes.recruitMe.feedbackChat,
    element: <RecruitMeFeedback />,
    loader: breadcrumbLoaderFunction([
      ...baseRecruitMeBreadcrumbs,
      {label: 'Feedback Chat'},
    ]),
  },
];
