import {type ReactNode} from 'react';

import {type ButtonProps} from '@mui/material/Button';
import IconButton, {type IconButtonProps} from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';

import {addShouldNotForwardProps} from '../../utils';

export interface OptixIconButtonProps extends IconButtonProps {
  // size?: TOptixButtonSizes;
  variant?: ButtonProps['variant'];
  loading?: boolean;
  icon?: ReactNode;
}

export interface StyledOptixIconButtonProps {
  $variant: ButtonProps['variant'];
}
export const StyledOptixIconButton = styled(IconButton, {
  shouldForwardProp: addShouldNotForwardProps('$variant'),
})<StyledOptixIconButtonProps>(({theme, color, $variant}) => {
  const c = color === 'default' ? 'primary' : color;
  if ($variant === 'contained') {
    return {
      background:
        c === 'inherit' ? 'inherit' : theme.palette[c ?? 'primary'].main,
      color:
        c === 'inherit'
          ? 'inherit'
          : (theme.palette[c ?? 'primary'].contrastText ??
            theme.palette.text.primary),
    };
  }
  if ($variant === 'outlined') {
    return {
      borderSize: 1,
      borderColor:
        c === 'inherit' ? 'inherit' : theme.palette[c ?? 'primary'].main,
      color: c === 'inherit' ? 'inherit' : theme.palette[c ?? 'primary'].main,
    };
  }
  return {};
});
export function OptixIconButton({
  size = 'medium',
  color = 'primary',
  variant = 'text',
  loading,
  children,
  icon,
  ...buttonProps
}: OptixIconButtonProps) {
  return (
    <StyledOptixIconButton
      disabled={loading}
      size={size}
      color={color}
      $variant={variant}
      {...buttonProps}
    >
      {icon ?? children}
    </StyledOptixIconButton>
  );
}
