import {memo, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import {AppsWidget} from './AppsWidget';
import {
  LogoImageContainer,
  LogoImageSmallContainer,
  SidNavContainer,
  SidNavContent,
} from './SideNav.style';
import {OptixNavigationButton} from './SideNavigationButton';
import {SideNavigationItem} from './SideNavigationItem';
import Optix from '../../../../assets/images/optix.png';
import SafeAndSoundLogoDark from '../../../../assets/images/S&S_Dark.png';
import SafeAndSoundLogoLight from '../../../../assets/images/S&S_Light.png';
import {type NavigationLink} from '../../constants/navigation';
import {config} from '../../services/configService';
import {useAuthStore} from '../../stores/authStore';
import {useNavigationStore} from '../../stores/navigationStore';
import {usePreferencesStore} from '../../stores/preferencesStore';

function SideNavigationComponent() {
  const navigate = useNavigate();
  const links = useNavigationStore((state) => state.links);
  const themeMode = usePreferencesStore((state) => state.themeMode);
  const sidePanelExpanded = usePreferencesStore(
    (state) => state.sidePanelExpanded,
  );
  const checkRoles = useAuthStore((state) => state.checkRoles);
  const toggleSidePanelExpanded = usePreferencesStore(
    (state) => state.toggleSidePanelExpanded,
  );

  const handleGoBackHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const validLinks = useMemo(
    () =>
      links.reduce((acc: NavigationLink[], link: NavigationLink) => {
        if (
          checkRoles(...link.roles) ||
          window.location.pathname === link.path
        ) {
          return [...acc, link];
        }
        return acc;
      }, []),
    [checkRoles, links],
  );

  return (
    <SidNavContainer role="navigation" $isExpanded={sidePanelExpanded}>
      <SidNavContent divider={<Divider flexItem />}>
        {sidePanelExpanded ? (
          <LogoImageContainer onClick={handleGoBackHome}>
            <img
              src={
                themeMode === 'light'
                  ? SafeAndSoundLogoLight
                  : SafeAndSoundLogoDark
              }
              alt="Safe & Sound"
            />
          </LogoImageContainer>
        ) : (
          <Stack px={1}>
            <LogoImageSmallContainer onClick={handleGoBackHome}>
              <img src={Optix} alt="Safe & Sound" />
            </LogoImageSmallContainer>
          </Stack>
        )}
        <Stack px={1}>
          <OptixNavigationButton
            icon={
              sidePanelExpanded ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRight />
              )
            }
            name="Collapse Side Panel"
            isActive={false}
            onClick={toggleSidePanelExpanded}
          />
        </Stack>
        <Stack flex={1} px={1}>
          {validLinks.map((item) => (
            <SideNavigationItem key={item.path} {...item} />
          ))}
        </Stack>
        <AppsWidget href={config.sso.appsUrl} />
      </SidNavContent>
    </SidNavContainer>
  );
}

export const SideNavigation = memo(SideNavigationComponent);
