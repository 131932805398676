import InputBase, {type InputBaseProps} from '@mui/material/InputBase';
import {styled} from '@mui/material/styles';

const StyledField = styled(InputBase)(({theme}) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #E9E9E9 ',
  fontSize: 16,
  width: 'auto',
  padding: '0 16px',
  '& .MuiInputBase-input': {
    padding: '12px 0',
  },
  // gap: '12px 16px',
  // Use the system font instead of the default Roboto font.
  '&.Mui-focused': {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  '&:active': {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export function Field(props: InputBaseProps) {
  return <StyledField {...props} />;
}
