import {type PaletteOptions} from '@mui/material/styles';

export const ThemeLightPalette: PaletteOptions = {
  background: {
    default: '#F1F1F1',
    paper: '#fff',
  },
  text: {
    primary: '#121212',
    secondary: '#525252',
    disabled: '#717171',
  },
};
