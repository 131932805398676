import {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import Paper from '@mui/material/Paper';

export function FormStateViewer() {
  const {formState} = useFormContext();

  const state = useMemo(
    () => ({
      defaultValues: formState.defaultValues,
      dirtyFields: formState.dirtyFields,
      errors: formState.errors,
      isDirty: formState.isDirty,
      isLoading: formState.isLoading,
      isSubmitSuccessful: formState.isSubmitSuccessful,
      isSubmitted: formState.isSubmitted,
      isSubmitting: formState.isSubmitting,
      isValid: formState.isValid,
      isValidating: formState.isValidating,
      submitCount: formState.submitCount,
      touchedFields: formState.touchedFields,
    }),
    [formState],
  );

  return (
    <Paper
      sx={{
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'sans-serif',
        p: 0,
        px: 2,
        m: 2,
        display: 'flex',
        placeContent: 'flex-start',
        width: 'fit-content',
        overflow: 'auto',
        flexWrap: 'wrap',
        maxWidth: '500px',
      }}
    >
      <pre>{JSON.stringify(state, undefined, 2)}</pre>
    </Paper>
  );
}
