import {type SyntheticEvent, useCallback, useState} from 'react';

import Autocomplete, {
  type AutocompleteInputChangeReason,
  type AutocompleteProps,
  type AutocompleteChangeDetails,
  type AutocompleteChangeReason,
  type AutocompleteValue,
  type AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

import {OptixAutocompleteRenderGroup} from './OptixAutocompleteRenderGroup';
import {OptixAutocompleteRenderTextField} from './OptixAutocompleteRenderTextField';

export interface OptixAutocompleteOption {
  label: string | undefined | null;
  value: string | number | boolean | undefined | null;
}

export interface OptixAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<
    AutocompleteProps<
      OptixAutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
    'renderGroup' | 'renderInput' | 'renderOption' | 'onChange' | 'options'
  > {
  placeholder?: string;
  name?: string;
  size?: 'small' | 'medium';
  label?: string;
  options: OptixAutocompleteOption[];
  onChange: (
    event: SyntheticEvent,
    value: AutocompleteValue<
      OptixAutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptixAutocompleteOption>,
  ) => void;
}

export function OptixAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  value,
  label,
  options = [],
  onChange,
  onInputChange,
  loading,
  placeholder,
  size = 'medium',
  ...autocompleteProps
}: OptixAutocompleteProps<Multiple, DisableClearable, FreeSolo>) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback(
    (
      event: SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      setInputValue(newInputValue);

      if (onInputChange != null) {
        onInputChange(event, newInputValue, reason);
      }
    },
    [onInputChange],
  );

  const handleIsOptionEqualToValue = useCallback(
    (
      option: OptixAutocompleteOption,
      currentValueAutocomplete: OptixAutocompleteOption,
    ) => option.value === currentValueAutocomplete.value,
    [],
  );

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <OptixAutocompleteRenderTextField
        loading={loading}
        placeholder={placeholder}
        label={label}
        {...params}
      />
    ),
    [label, loading, placeholder],
  );

  return (
    <Autocomplete
      isOptionEqualToValue={handleIsOptionEqualToValue}
      value={value}
      fullWidth
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      size={size}
      openText={placeholder}
      renderInput={handleRenderInput}
      renderGroup={OptixAutocompleteRenderGroup}
      options={options}
      loading={loading}
      {...autocompleteProps}
    />
  );
}
