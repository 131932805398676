import {useEffect, useMemo, useState} from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {z} from 'zod';

import {recruitMeApi} from '../../../api';
import {
  OptixForm,
  OptixSubmit,
  OptixTextFieldHook,
} from '../../../ui-lib/components';
import {OptixModal} from '../../../ui-lib/components/table/OptixModal';
import {useOrganisationStore} from '../../common/stores/organisationStore';
import {isValidUUID, type UUID} from '../../common/types/UUID';
import {isObjectWithKeys} from '../../common/utils/unknownhelper';

export const validationSchema = z.object({
  fullName: z.string(),
  id: z.string().optional(),
  email: z.string(),
  contactNumber: z.string(),
  identificationNumber: z.string(),
});

export type UpdateRecruitMeDetails = z.infer<typeof validationSchema>;

const StyledCard = styled(Card)(() => ({
  display: 'flex',
  width: '700px',
  height: '595px',
  padding: 20,
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledCardHeader = styled(Stack)(({theme}) => ({
  top: 0,
  left: 0,
  zIndex: 2,
  paddingTop: 12,
  position: 'sticky',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'stretch',
  backgroundColor: '#fff',
}));

const StyledDivider = styled(Divider)(({theme}) => ({
  marginY: theme.spacing(1),
}));

export default function RecruitMe() {
  const [urlExternal, setURL] = useState<string>();
  const [loading, setloading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showErrorPopUp, setShowErrorPopup] = useState<boolean>(false);
  const organisationId = useOrganisationStore((state) => state.organisationId);
  const organisationGroupId = useOrganisationStore(
    (state) => state.organisationGroupId,
  );

  const defaultValues = useMemo(
    () => ({
      fullName: undefined,
      id: '',
      email: '',
      contactNumber: undefined,
      identificationNumber: undefined,
    }),
    [],
  );

  useEffect(() => {
    const handlePostMessage = (value: unknown) => {
      if (
        !(
          isObjectWithKeys(value, 'date') &&
          isObjectWithKeys(value.date, 'event')
        )
      ) {
        return;
      }

      if (value.date.event === 'interview-completed') {
        setShowButton(true);
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  const getCandidateId = async (
    fullName: string,
    organisationId: UUID,
    organisationGroupId: UUID,
    phoneNumber: string,
    email: string,
    id: UUID,
  ) => {
    if (!isValidUUID(organisationId)) {
      return null;
    }
    try {
      return await recruitMeApi.createCandidate({
        candidateCreation: {
          fullName,
          organisationId,
          organisationGroupId,
          phoneNumber,
          email,
          identificationNumber: id,
        },
      });
    } catch (error) {
      setShowErrorPopup(true);
      return null;
    }
  };

  const getAssessmentResponse = async (
    candidateId: UUID,
    organisationId: UUID,
  ) => {
    if (!isValidUUID(organisationId) || !isValidUUID(candidateId)) {
      return null;
    }
    try {
      const response = await recruitMeApi.createAssessment({
        assessmentRequest: {candidateId, orgId: organisationId},
      });

      if (response != null) {
        if (
          !response.isCreated ||
          response.url == null ||
          response.id == null
        ) {
          throw new Error('There was an error creating the assessment');
        }
        return response;
      }
    } catch (error) {
      setShowErrorPopup(true);
      return {url: ''};
    }
  };

  const simulateEndpointCalls = async (values: UpdateRecruitMeDetails) => {
    try {
      const candidateId = await getCandidateId(
        values.fullName,
        organisationId,
        organisationGroupId,
        values.contactNumber,
        values.email,
        values.identificationNumber,
      );

      if (candidateId?.id == null) {
        return;
      }

      const assessmentResponse = await getAssessmentResponse(
        candidateId?.id,
        organisationId,
      );

      setURL(assessmentResponse?.url);
    } catch (error) {
      setShowErrorPopup(true);
    } finally {
      setloading(false);
    }
  };

  return (
    <Stack flex={1}>
      <OptixModal
        isOpen={showErrorPopUp}
        defaultValues={defaultValues}
        title="Error Occurred"
        onClose={() => {
          setShowErrorPopup(false);
        }}
      >
        <Stack spacing={2}>Please try again</Stack>
      </OptixModal>
      {showButton && (
        <OptixModal
          isOpen={showButton}
          buttonText="Return to Form"
          defaultValues={defaultValues}
          title="Congratulations!"
          onClose={() => {
            setURL('');
            setShowButton(false);
          }}
        >
          <Stack spacing={2}>You have completed the assessment</Stack>
        </OptixModal>
      )}
      <Stack flex={1}>
        {urlExternal?.includes('https') == null ? (
          <StyledCard>
            <OptixModal
              isOpen={showErrorPopUp}
              defaultValues={defaultValues}
              title="Error Occurred"
              onClose={() => {
                setShowErrorPopup(false);
              }}
            >
              <Stack spacing={2}>Please try again</Stack>
            </OptixModal>
            <Stack direction="column" spacing={2}>
              <StyledCardHeader>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>Basic Details</Typography>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography variant="body2" color="text.secondary">
                    The Recruit Me assessment conducted for recruitment and
                    selection purposes prioritises the protection of personal
                    information in accordance with the applicable laws and
                    regulations. The information collected during this
                    assessment will be treated with the utmost confidentiality
                    and used solely for the intended purposes of evaluating
                    candidates&apos; suitability for the specified role. We
                    ensure that appropriate security measures are in place to
                    safeguard personal information, and access to it will be
                    limited to authorised personnel only. By completing this
                    assessment, you acknowledge and give your consent to the
                    collection, processing, and storage of your personal data
                    for recruitment and selection purposes.
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Completion of the assessment does not guarantee employment
                  </Typography>
                </Stack>
                <StyledDivider flexItem />
              </StyledCardHeader>
              <OptixForm<UpdateRecruitMeDetails>
                onSubmit={async (values) => {
                  setloading(true);
                  await simulateEndpointCalls(values);
                }}
                validationSchema={validationSchema}
                defaultValues={defaultValues}
              >
                <Stack direction="column" spacing={2}>
                  <OptixTextFieldHook
                    variant="standard"
                    name="fullName"
                    label="Name & Surname"
                  />
                  <OptixTextFieldHook
                    variant="standard"
                    name="email"
                    label="Email Address"
                  />
                  <OptixTextFieldHook
                    variant="standard"
                    name="contactNumber"
                    label="Cell number"
                  />
                  <OptixTextFieldHook
                    variant="standard"
                    name="identificationNumber"
                    label="Identification number"
                  />
                </Stack>
                <OptixSubmit
                  style={{marginTop: 24}}
                  color="secondary"
                  fullWidth={false}
                  text="Submit"
                  size="small"
                  loading={loading}
                />
              </OptixForm>
            </Stack>
          </StyledCard>
        ) : (
          <Stack flex={1} spacing={2}>
            <iframe
              title="External Site"
              src={urlExternal}
              width="100%"
              style={{display: 'flex', flex: 1}}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
