import {memo} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Loader} from './Loader';

interface PageLoaderProps {
  message: string;
}

function PageLoaderComponent({message}: PageLoaderProps) {
  return (
    <Stack
      flex={1}
      flexGrow={1}
      spacing={1}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Loader size="lg" />
      <Typography color="text.primary">{message}</Typography>
    </Stack>
  );
}

export const PageLoader = memo(PageLoaderComponent);
