import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';

import Optix from '../../../../assets/images/OptixSafelyHome.png';

const StyledOptixButton = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(1.5),
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const StyledOptixLogo = styled(Box)(() => ({
  maxWidth: '8rem',
  width: '100%',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > img': {
    display: 'flex',
    flex: 1,
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
}));

export function AppsWidget({
  href,
  disabled,
}: {
  href: string;
  disabled?: boolean;
}) {
  const onNavigate = () => {
    window.open(href);
  };
  return (
    <StyledOptixButton disabled={disabled} onClick={onNavigate}>
      <StyledOptixLogo>
        <img src={Optix} alt="Optix" />
      </StyledOptixLogo>
    </StyledOptixButton>
  );
}
