import {useCallback, useEffect} from 'react';
import {useNavigate, useRouteError} from 'react-router-dom';

import Home from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {routes} from '../constants/routes';
import {useAuthStore} from '../stores/authStore';

export function ErrorBoundary() {
  const theme = useTheme();
  const navigate = useNavigate();
  const routeError = useRouteError();

  useEffect(() => {
    const el = document.querySelector('#splash-screen');
    el?.remove();
  }, []);

  const handleGoHome = useCallback(() => {
    if (useAuthStore.getState().token != null) {
      navigate(routes.landing);
    } else {
      navigate(routes.login);
    }
  }, [navigate]);

  return (
    <Stack
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgcolor="background.default"
      color="text.primary"
    >
      <Stack>
        <Typography variant="h2">Sorry,</Typography>
        <Typography variant="h2">an unknown error occurred</Typography>
      </Stack>
      {routeError != null &&
      typeof routeError === 'object' &&
      'message' in routeError &&
      typeof routeError.message === 'string' ? (
        <Stack
          my={4}
          p={4}
          bgcolor="background.paper"
          borderRadius={theme.shape.borderRadius}
        >
          {routeError.message}
        </Stack>
      ) : null}
      <Button size="large" startIcon={<Home />} onClick={handleGoHome}>
        Return to the known
      </Button>
    </Stack>
  );
}
