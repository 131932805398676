import {toast} from 'react-toastify';

import {jwtDecode} from 'jwt-decode';
import {create, type StateCreator} from 'zustand';
import {devtools, persist} from 'zustand/middleware';

import {hasCommonElements} from '../../../ui-lib/utils';
import {type Role} from '../constants/roles';
import {config} from '../services/configService';

export interface AuthStoreState {
  didValidate: boolean;
  roles: string[];
  expiresAt?: Date | undefined;
  token: string | undefined;
  decodedToken: object | undefined;
  refreshToken: string | undefined;
}

export interface AuthStoreActions {
  reset: () => void;
  checkRoles: (...args: Role[]) => boolean;
  setDidValidate: (didValidate: AuthStoreState['didValidate']) => void;
  setTokens: (
    token: AuthStoreState['token'],
    refreshToken: AuthStoreState['refreshToken'],
    expiresAt: AuthStoreState['expiresAt'],
  ) => void;
  logout: () => void;
}

const authStoreInitialState: AuthStoreState = {
  didValidate: false,
  roles: [],
  expiresAt: undefined,
  token: undefined,
  refreshToken: undefined,
  decodedToken: undefined,
};

const getRolesFromToken = (jwtToken: object) => {
  const clientId = config.sso.apiClientId as unknown;

  if (
    'resource_access' in jwtToken &&
    jwtToken.resource_access != null &&
    typeof jwtToken.resource_access === 'object' &&
    clientId != null &&
    typeof clientId === 'string' &&
    clientId in jwtToken.resource_access &&
    (jwtToken.resource_access as never)[clientId] != null &&
    typeof (jwtToken.resource_access as never)[clientId] === 'object' &&
    'roles' in (jwtToken.resource_access as never)[clientId] &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.isArray((jwtToken.resource_access as any)[clientId].roles)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (jwtToken.resource_access as any)[clientId].roles;
  }
  return [];
};

export type AuthStore = AuthStoreState & AuthStoreActions;

const authStoreHandler: StateCreator<AuthStore> = (setState, getState) => ({
  ...authStoreInitialState,
  reset: () => {
    setState(authStoreInitialState);
  },
  setDidValidate: (didValidate) => {
    setState({didValidate});
  },
  setTokens: (token, refreshToken, expiresAt) => {
    const jwtToken = token != null ? jwtDecode(token) : null;
    const roles = jwtToken != null ? getRolesFromToken(jwtToken) : [];
    setState({
      token,
      roles,
      refreshToken,
      decodedToken: token != null ? jwtDecode(token) : undefined,
      expiresAt,
    });
  },
  checkRoles: (...args) => {
    if (args.length === 0) {
      return true;
    }

    const {token, roles, decodedToken, expiresAt} = getState();

    if (expiresAt != null && expiresAt <= new Date()) {
      toast.error('Token expired');
      return false;
    }

    let jwtToken: unknown = null;

    if (decodedToken != null) {
      jwtToken = decodedToken;
    } else if (token != null) {
      jwtToken = jwtDecode(token);
    }

    let userRoles = roles;

    if (
      userRoles.length === 0 &&
      typeof jwtToken === 'object' &&
      jwtToken != null
    ) {
      userRoles = getRolesFromToken(jwtToken);
    }

    if (userRoles.length > 0) {
      return hasCommonElements(args, userRoles);
    }

    return false;
  },
  logout: () => {
    // resetAllStores();
    setState(authStoreInitialState);
    localStorage.removeItem('auth');
  },
});

const AuthStoreMiddleWare = (f: StateCreator<AuthStore>) =>
  devtools(
    persist(f, {
      name: 'auth',
      partialize: (state) => ({
        token: state.token,
        refreshToken: state.refreshToken,
        expiresAt: state.expiresAt,
      }),
    }),
  );

export const useAuthStore = create<AuthStore>()(
  AuthStoreMiddleWare(authStoreHandler),
);
