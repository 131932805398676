import {type ReactNode, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import {OptixNavigationButton} from './SideNavigationButton';

export interface SideNavigationItemProps {
  path: string;
  icon: ReactNode;
  name: string;
  enableClick?: boolean;
}

export function SideNavigationItem({
  path,
  icon,
  name,
  enableClick = true,
}: SideNavigationItemProps) {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isActive = path === pathname;

  const handleNavigate = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <Tooltip title={name} placement="right" arrow>
      <Box>
        <OptixNavigationButton
          icon={icon}
          name={name}
          isActive={isActive}
          onClick={enableClick ? handleNavigate : undefined}
        />
      </Box>
    </Tooltip>
  );
}
