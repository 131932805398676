import {useCallback} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useAuth} from 'oidc-react';

import Logo from './assets/images/S&S_Light.png';
import {useAuthStore} from './modules/common/stores/authStore';

export function OrganisationWrapperError() {
  const logout = useAuthStore((state) => state.logout);
  const {signOutRedirect} = useAuth();

  const handleLogout = useCallback(async () => {
    logout();
    await signOutRedirect();
  }, [logout, signOutRedirect]);

  return (
    <Stack flex={1} spacing={2} justifyContent="center" alignItems="center">
      <StyledOptixLogo>
        <img src={Logo} alt="Optix" />
      </StyledOptixLogo>
      <Typography>No organisation assigned, please contact support.</Typography>
      <Button variant="contained" color="primary" onClick={handleLogout}>
        Logout
      </Button>
    </Stack>
  );
}
const StyledOptixLogo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    display: flex;
    flex: 1;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
