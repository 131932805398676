import {type ReactNode} from 'react';

import {PageLoader} from './modules/common/components/PageLoader';
import {useOrganisationData} from './modules/common/hooks/useOrganisationData';
import {useOrganisationStore} from './modules/common/stores/organisationStore';
import {SelectOrganisationModel} from './modules/home/components/SelectOrganisationModel';
import {Home} from './modules/home/pages/Home';
import {OrganisationWrapperError} from './OrganisationWrapperError';

interface OrganisationWrapperProps {
  children: ReactNode;
}
export function OrganisationWrapper({children}: OrganisationWrapperProps) {
  const [organisationsQuery, organisationGroupQuery] = useOrganisationData();
  const selectedOrganisation = useOrganisationStore(
    (state) => state.organisationId,
  );
  const selectedOrganisationGroup = useOrganisationStore(
    (state) => state.organisationGroupId,
  );

  if (organisationsQuery.isLoading || organisationGroupQuery.isLoading) {
    return <PageLoader message="Loading organisations" />;
  }

  if (organisationsQuery.isError || organisationGroupQuery.isError) {
    return <OrganisationWrapperError />;
  }

  if (
    selectedOrganisation == null ||
    selectedOrganisation === '' ||
    selectedOrganisationGroup == null ||
    selectedOrganisationGroup === ''
  ) {
    return (
      <>
        <Home />
        <SelectOrganisationModel open={true} />
      </>
    );
  }

  return children;
}
