import {type AutocompleteRenderGroupParams} from '@mui/material/Autocomplete';
import {styled} from '@mui/material/styles';

const GroupHeader = styled('div')`
  position: sticky;
  padding: 10px;
  color: ${({theme}) => theme.palette.text.primary};
  background-color: ${({theme}) => theme.palette.background.paper};
`;

const GroupItems = styled('ul')`
  padding: 0;
`;

export function OptixAutocompleteRenderGroup({
  group,
  children,
  key,
}: AutocompleteRenderGroupParams) {
  return (
    <li key={key}>
      <GroupHeader>{group}</GroupHeader>
      <GroupItems>{children}</GroupItems>
    </li>
  );
}
