import {useCallback} from 'react';
import {type FieldPath, type FieldValues} from 'react-hook-form';

import {
  OptixAutocomplete,
  type OptixAutocompleteProps,
} from '../../../autocomplete/OptixAutocomplete';
import {type ControllerRenderProps} from '../../formTypes';

export type OptixTextFieldHookRenderProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = Omit<
  OptixAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  'name' | 'defaultValue' | 'value' | 'onChange' | 'onBlur'
>;

export type OptixTextFieldHookRenderComponentProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerRenderProps<TFieldValues, TName> & OptixTextFieldHookRenderProps;

export function OptixAutocompleteHookRender<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field,
  fieldState,
  formState,
  ...autocompleteProps
}: OptixTextFieldHookRenderComponentProps<TFieldValues, TName>) {
  const onChange = useCallback(
    (_event: unknown, value: unknown) => {
      field.onChange(value);
    },
    [field],
  );

  /** We place the {...autocompleteProps} after the "local" props so that they can be overwritten */
  return (
    <OptixAutocomplete
      {...autocompleteProps}
      options={autocompleteProps.options ?? []}
      getOptionLabel={(option) => option.label ?? ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      name={field.name}
      onBlur={field.onBlur}
      onChange={onChange}
      value={field.value}
      /**
       * This will make the textField fullWidth true by default when you don't
       * pass a fullWidth.
       * Pass `fullWidth={false}`, if you don't want it to have fullWidth.
       */
      fullWidth={
        autocompleteProps.fullWidth === undefined || autocompleteProps.fullWidth
      }
    />
  );
}
