import {create} from 'zustand';

export interface Breadcrumb {
  label: string;
  path?: string | null;
}

interface BreadcrumbStoreState {
  breadcrumbs: Breadcrumb[];
}

interface BreadcrumbStoreActions {
  reset: () => void;
}

const authStoreInitialState: BreadcrumbStoreState = {
  breadcrumbs: [],
};

export type BreadcrumbStore = BreadcrumbStoreState & BreadcrumbStoreActions;

export const useBreadcrumbStore = create<BreadcrumbStore>((setState) => ({
  ...authStoreInitialState,
  reset: () => {
    setState(authStoreInitialState);
  },
}));

export const breadcrumbLoaderFunction = (breadcrumbs: Breadcrumb[]) => () => {
  useBreadcrumbStore.setState({
    breadcrumbs,
  });
  return null;
};
