import {type ReactNode, useCallback, useEffect, useState} from 'react';

import {
  AuthProvider,
  type AuthProviderProps,
  useAuth,
  UserManager,
  WebStorageStateStore,
} from 'oidc-react';

import {Loader} from './modules/common/components/Loader';
import {routes} from './modules/common/constants/routes';
import {config} from './modules/common/services/configService';
import {useAuthStore} from './modules/common/stores/authStore';
import {useUserStore} from './modules/common/stores/userStore';

const userManager = new UserManager({
  scope: 'openid profile email',
  authority: config.sso.loginUri,
  client_id: config.sso.clientId ?? '',
  redirect_uri: window.origin + routes.authRedirect,
  post_logout_redirect_uri: window.origin + routes.authRedirect,
  loadUserInfo: true,
  monitorSession: true,
  automaticSilentRenew: true,
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({
    prefix: 'oidcAuthStore',
    store: localStorage,
  }),
});

const oidcConfig: AuthProviderProps = {autoSignIn: true, userManager};

export function AuthWrapper({children}: {children: ReactNode}) {
  return (
    <AuthProvider {...oidcConfig}>
      <AuthWrapperHandler>{children}</AuthWrapperHandler>
    </AuthProvider>
  );
}

export function AuthWrapperHandler({children}: {children: ReactNode}) {
  const setTokens = useAuthStore((state) => state.setTokens);
  const logout = useAuthStore((state) => state.logout);
  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const {userData, signOutRedirect, isLoading: isAuthLoading} = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = useCallback(async () => {
    logout();
    await signOutRedirect();
  }, [logout, signOutRedirect]);

  useEffect(() => {
    if (userData?.expired === true) {
      void handleLogout();
    } else if (
      userData?.access_token != null &&
      userData?.refresh_token != null
    ) {
      const timestamp = (userData.expires_at ?? 0) * 1000; // Convert to milliseconds
      const date = new Date(timestamp);
      setTokens(userData.access_token, userData.refresh_token, date);
      const userDetails: Record<string, unknown> = {
        name: undefined,
        email: undefined,
        email_verified: undefined,
        preferred_username: undefined,
        given_name: undefined,
        family_name: undefined,
      };
      Object.keys(userData.profile).forEach((key) => {
        if (key in userDetails) {
          userDetails[key] = userData.profile[key];
        }
      });
      setUserDetails(userDetails);
    }

    return () => {
      if (userData?.expired === true) {
        void handleLogout();
      }
    };
  }, [userData, setTokens, setUserDetails, signOutRedirect, handleLogout]);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        await userManager.signinSilent();
      } catch (error) {
        void handleLogout();
      }
    };

    if (userData?.expires_in != null) {
      const timeoutId = setTimeout(
        () => {
          void refreshToken();
        },
        (userData.expires_in - 60) * 1000,
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [handleLogout, userData?.expires_in]);

  useEffect(() => {
    userManager.events.addUserSignedOut(handleLogout);

    return () => {
      userManager.events.removeUserSignedOut(handleLogout);
    };
  }, [handleLogout]);

  useEffect(() => {
    if (userData !== undefined) {
      setIsLoading(false);
    }
  }, [userData]);

  if (isLoading || isAuthLoading) {
    return (
      <Loader
        text="Authenticating"
        stackProps={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
      />
    );
  }

  return children;
}
