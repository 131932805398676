import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface InvalidRoleProps {
  role: string;
}

export default function InvalidRole({role}: InvalidRoleProps) {
  return (
    <Stack
      flex={1}
      flexGrow={1}
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <ErrorOutlineIcon fontSize="large" />
      <Typography variant="h5">
        You do not have the necessary permission for the page
      </Typography>
      <Typography variant="h6">
        Required permission: <strong>{role}</strong>
      </Typography>
    </Stack>
  );
}
