import {type PaletteColor, type PaletteOptions} from '@mui/material/styles';

export const EmpowerMePrimaryColor: PaletteColor = {
  main: '#fdaf27',
  light: '#FDCE27',
  dark: '#fdaf27',
  contrastText: '#fff',
};

export const ThemeEmpowerMePalette: PaletteOptions = {
  primary: EmpowerMePrimaryColor,
};
