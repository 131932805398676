import {Table} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

import {Loader} from '../../../../modules/common/components/Loader';

export interface OptixTableBaseNoDataRowProps {
  loading: boolean | undefined;
  loadingText: string | undefined;
  colSpan: number;
}

export function OptixTableBaseNoDataRow({
  loading = false,
  loadingText,
  colSpan = 1,
  children,
  ...props
}: OptixTableBaseNoDataRowProps & Table.RowProps) {
  if (loading) {
    return (
      <Table.NoDataRow {...props}>
        <StyledCell colSpan={colSpan}>
          <Loader size="lg" text={loadingText} />{' '}
        </StyledCell>
      </Table.NoDataRow>
    );
  }

  return <Table.NoDataRow {...props}>{children}</Table.NoDataRow>;
}

const StyledCell = styled('td')(({theme}) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));
