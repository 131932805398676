import {type FieldValues, useFormContext} from 'react-hook-form';

import {OptixButton, type OptixButtonProps} from '../../../button';

export interface OptixSubmitProps extends OptixButtonProps {
  submitText?: string;
  submitting?: boolean;
  fullWidth?: boolean;
}

export function OptixSubmit<TFieldValues extends FieldValues = FieldValues>({
  submitText = 'Submit',
  submitting = false,
  fullWidth = true,
  ...buttonProps
}: OptixSubmitProps) {
  const {formState} = useFormContext<TFieldValues>();

  return (
    <OptixButton
      variant="contained"
      color={Object.keys(formState.errors).length > 0 ? 'error' : 'primary'}
      type="submit"
      loading={submitting || formState.isSubmitting || formState.isLoading}
      fullWidth={fullWidth}
      {...buttonProps}
    >
      {submitText}
    </OptixButton>
  );
}
