import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export interface EmpowerMeCardStatProps {
  name: string | undefined;
  isDriverActive: boolean | undefined;
}

export function EmpowerMeCardStat({
  name,
  isDriverActive = false,
}: EmpowerMeCardStatProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      zIndex={0}
    >
      <Typography>{name}</Typography>
      <Stack
        flex={1}
        direction="row"
        justifyContent="flex-end"
        spacing={5}
        alignItems="center"
      >
        <Typography>
          {isDriverActive ? 'Driver Visible' : 'Not Visble to Driver'}
        </Typography>
      </Stack>
    </Stack>
  );
}
