import {memo, type ReactNode, Suspense, useCallback, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Logout from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {styled, useTheme} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {useAuth} from 'oidc-react';

import {SideNavigation} from './SideNavigation';
import {PageLoader} from '../PageLoader';
import {AppShellBreadcrumbs} from './AppShellBreadcrumbs';
import {SelectOrganisationModel} from '../../../home/components/SelectOrganisationModel';
import {routes} from '../../constants/routes';
import {useAuthStore} from '../../stores/authStore';
import {useOrganisationStore} from '../../stores/organisationStore';
import {useUserStore} from '../../stores/userStore';

interface AppShellProps {
  children?: ReactNode;
}

const Shell = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row',
  flex: 1,
  flexGrow: 1,
  overflow: 'hidden',
}));

const ContentContainer = styled(Stack)(({theme}) => ({
  flex: 1,
  flexGrow: 1,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  overflowY: 'auto',
}));

function AppShellComponent({children}: AppShellProps) {
  const theme = useTheme();
  const logout = useAuthStore((state) => state.logout);
  const name = useUserStore((state) => state.name);
  const organisationName = useOrganisationStore(
    (state) => state.organisationName,
  );
  const organisationGroupName = useOrganisationStore(
    (state) => state.organisationGroupName,
  );
  const {signOut, signOutRedirect} = useAuth();

  const [openSelectOrganisation, setOpenSelectOrganisation] = useState(false);

  const navigate = useNavigate();

  const onGoToProfile = useCallback(() => {
    navigate(routes.profile);
  }, [navigate]);

  const onOpenSelectOrganisation = useCallback(() => {
    setOpenSelectOrganisation(true);
  }, []);

  const onCloseSelectOrganisation = useCallback(() => {
    setOpenSelectOrganisation(false);
  }, []);

  const handleLogout = useCallback(async () => {
    logout();
    await signOutRedirect({redirectMethod: 'replace'});
    await signOut();
  }, [logout, signOut, signOutRedirect]);

  return (
    <Shell role="main" data-testid="shell">
      <SideNavigation />
      <Stack flex={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          bgcolor="background.paper"
          p={1}
          gap={2}
          borderLeft={`1px solid ${theme.palette.divider}`}
        >
          <Stack flex={1} alignItems="center" direction="row">
            <AppShellBreadcrumbs />
          </Stack>
          <Tooltip
            title={`${organisationName} (${organisationGroupName})`}
            data-testid="organisation-tooltip"
            arrow
          >
            <Button
              size="small"
              color="inherit"
              startIcon={<ApartmentIcon />}
              onClick={onOpenSelectOrganisation}
              data-testid="organisation"
            >
              Organisation
            </Button>
          </Tooltip>
          <SelectOrganisationModel
            open={openSelectOrganisation}
            onClose={onCloseSelectOrganisation}
          />
          <Tooltip title={name} arrow>
            <Button
              size="small"
              color="inherit"
              startIcon={<AccountCircle />}
              onClick={onGoToProfile}
              data-testid="profile"
            >
              Profile
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<Logout />}
            onClick={handleLogout}
            data-testid="logout"
          >
            Logout
          </Button>
        </Box>
        <ContentContainer data-testid="contentContainer">
          <Suspense fallback={<PageLoader message="Preparing content" />}>
            {children ?? <Outlet />}
          </Suspense>
        </ContentContainer>
      </Stack>
    </Shell>
  );
}
export const AppShell = memo(AppShellComponent);
