import {css, styled} from '@mui/material/styles';

export const StyledToastContainer = styled('div')`
  ${({theme}) => css`
    .Toastify__toast-theme--dark,
    .Toastify__toast-theme--light {
      background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
    }
    .Toastify__progress-bar-theme--dark.Toastify__progress-bar--default,
    .Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
      background-color: ${theme.palette.primary.main};
    }
    .Toastify__progress-bar-theme--dark.Toastify__progress-bar--success,
    .Toastify__progress-bar-theme--light.Toastify__progress-bar--success {
      background-color: ${theme.palette.success.main};
    }
    .Toastify__toast--success .Toastify__toast-icon svg {
      fill: ${theme.palette.success.main};
    }
    .Toastify__progress-bar-theme--dark.Toastify__progress-bar--error,
    .Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
      background-color: ${theme.palette.error.main};
    }
    .Toastify__toast--error .Toastify__toast-icon svg {
      fill: ${theme.palette.error.main};
    }
    .Toastify__progress-bar-theme--dark.Toastify__progress-bar--warning,
    .Toastify__progress-bar-theme--light.Toastify__progress-bar--warning {
      background-color: ${theme.palette.warning.main};
    }
    .Toastify__toast--warning .Toastify__toast-icon svg {
      fill: ${theme.palette.warning.main};
    }
    .Toastify__progress-bar-theme--dark.Toastify__progress-bar--info,
    .Toastify__progress-bar-theme--light.Toastify__progress-bar--info {
      background-color: ${theme.palette.secondary.main};
    }
    .Toastify__toast--info .Toastify__toast-icon svg {
      fill: ${theme.palette.secondary.main};
    }
  `}
`;
