import {type PaletteColor, type PaletteOptions} from '@mui/material/styles';

export const TrainMePrimaryColor: PaletteColor = {
  main: '#53A730',
  light: '#53A730',
  dark: '#53A730',
  contrastText: '#fff',
};

export const ThemeTrainMePalette: PaletteOptions = {
  primary: TrainMePrimaryColor,
};
