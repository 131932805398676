import {useState} from 'react';

import QrCodeIcon from '@mui/icons-material/QrCode';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styled} from '@mui/material/styles';

import {mobileAuthApi} from '../../../../../../api';
import {Loader} from '../../../../../../modules/common/components/Loader';
import {useOrganisationStore} from '../../../../../../modules/common/stores/organisationStore';
import {OptixButton} from '../../../../button';
import {toast} from '../../../../toastify';

interface OptixTableEditActionProps {
  row: {
    firstname: string;
    lastname: string;
    user?: {
      id: string;
    };
  };
}

interface GetQrCodeResponse {
  username?: string | null;
  hasQr: boolean;
  message: string;
  encodedImage: string;
}

type Loading = 'loading' | 'loaded' | 'MissingId' | false;

const StyLedQRButton = styled(OptixButton)`
  & .MuiButton-icon {
    margin-right: 0;
  }
`;

const StyledTitle = styled(DialogTitle)`
  padding-bottom: 0;
  padding-top: 40px;
  display: flex;
  justify-content: center;
`;

export default function OptixTableViewQRAction({
  row,
}: OptixTableEditActionProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [loadingQr, setLoadingQr] = useState<Loading>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const organisationId = useOrganisationStore((state) => state.organisationId);

  const handleDialogOpen = async () => {
    setOpen(true);
    await fetchImage();
  };

  const fetchImage = async () => {
    try {
      if (row?.user?.id != null && row?.user?.id !== '') {
        setLoadingQr('loading');

        // Typecast needed due to openapi return type being Promise<object>
        const response = (await mobileAuthApi.getQrCode({
          organisationId,
          userId: row.user.id,
        })) as GetQrCodeResponse;
        setImageUrl(`data:image/png;base64,${response.encodedImage}`);
        setLoadingQr('loaded');
      } else {
        setLoadingQr('MissingId');
      }
    } catch {
      toast('QR code genberator returned an error', 'error');
    }
  };

  return (
    <>
      <StyLedQRButton
        startIcon={<QrCodeIcon />}
        onClick={handleDialogOpen}
        color="inherit"
        variant="text"
        size="small"
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setLoadingQr(false);
          setImageUrl('');
        }}
      >
        {loadingQr === 'loading' && (imageUrl ?? '') === '' ? (
          <DialogContent sx={{display: 'flex', placeContent: 'center'}}>
            <Loader size="lg" />
          </DialogContent>
        ) : null}

        {imageUrl !== '' &&
          loadingQr !== false &&
          loadingQr !== 'MissingId' && (
            <>
              <StyledTitle>{`QR for ${row.firstname ?? ''} ${row.lastname ?? ''}`}</StyledTitle>
              <DialogContent>
                <img src={imageUrl} alt="QR Code" />
              </DialogContent>
            </>
          )}
        {loadingQr === false || loadingQr === 'MissingId' ? (
          <DialogContent>
            <DialogContentText>{`No QR found for ${row.firstname} ${row.lastname}`}</DialogContentText>
          </DialogContent>
        ) : null}
      </Dialog>
    </>
  );
}
