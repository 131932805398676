import {type PropsWithChildren} from 'react';

import Button from '@mui/material/Button';
import {type ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

import {Loader} from '../../../modules/common/components/Loader';

export interface OptixButtonProps extends ButtonProps {
  loading?: boolean;
  loadingText?: string;
  text?: string;
  buttonProps?: ButtonProps;
}

export const StyledOptixButton = styled(Button)(() => ({
  textDecoration: 'none',
  textTransform: 'none',
  whitespace: 'nowrap',
}));

export function OptixButton({
  loadingText = 'Submitting...',
  loading = false,
  text,
  children,
  buttonProps,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  ...props
}: PropsWithChildren<OptixButtonProps>) {
  return (
    <StyledOptixButton
      variant={variant}
      size={size}
      color={color}
      startIcon={loading ? <Loader size="xs" /> : undefined}
      disabled={loading}
      {...props}
      {...buttonProps}
    >
      {loading ? loadingText : (children ?? text)}
    </StyledOptixButton>
  );
}
